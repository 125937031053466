import React from 'react'

function validateContextName(contextName) {
  if (!(/^[A-Z][a-z]+(?:[A-Z][a-z]+)*$/.test(contextName))) {
    throw new Error('Context name must contain no spaces and lead with a capital letter')
  }
}

export default function contextFactory({ defaultState, reducer, contextName }) {
  validateContextName(contextName)

  const Context = React.createContext(defaultState)
  Context.displayName = contextName
  const DispatchContext = React.createContext()
  DispatchContext.displayName = `${contextName}-dispatch`

  function ContextProvider({ children, customState = {} }) {
    const initState = { ...defaultState, ...customState }
    const [ state, dispatch ] = React.useReducer(reducer, initState)

    return (
      <Context.Provider value={state} displayName={contextName}>
        <DispatchContext.Provider value={dispatch}>
          {children}
        </DispatchContext.Provider>
      </Context.Provider>
    )
  }

  function useState() {
    const context = React.useContext(Context)

    if (context === undefined) {
      throw new Error(`use${contextName} must be used within a(n) ${contextName}ContextProvider`)
    }

    return context
  }

  function useDispatch() {
    const context = React.useContext(DispatchContext)

    if (context === undefined) {
      throw new Error(`use${contextName}Dispatch must be used within a(n) ${contextName}ContextProvider`)
    }

    return context
  }

  return {
    ContextProvider,
    useState,
    useDispatch,
  }
}
