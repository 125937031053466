import React from 'react'
import { Button, Modal, Loader } from 'semantic-ui-react'

const LoadingBody = () => (
  <div style={{ height: '200px' }}>
    <Loader
      style={{ color: 'black', size: 'big' }}
      className='workaround'
      inverted
    />
  </div>
)

const ErrorBody = errorText => (
  <div style={{ height: '100px', padding: '30px' }}>
    <div style={{ margin: 'auto', textAlign: 'center' }}>
      <div>
        Something went wrong!
      </div>
      <div>
        {errorText}
      </div>
    </div>
  </div>
)

const TriggerableFullScreenModal = ({
  open,
  loading,
  error,
  errorText,
  setOpen,
  titleText,
  closeButtonText,
  /* we are not using children to prevent their evaluation in case
  /* we are loading something or that there is an error */
  renderContent,
  closeButtonDisabled,
}) => (
  <Modal
    onClose={() => setOpen(false)}
    onClick={event => event.stopPropagation()}
    open={open}
  >
    <Modal.Header>{ titleText }</Modal.Header>
    { loading ? LoadingBody() : (error ? ErrorBody(errorText) : renderContent())}
    <Modal.Actions>
      <Button
        content={closeButtonText}
        labelPosition='right'
        icon='checkmark'
        positive
        disabled={closeButtonDisabled}
        onClick={() => setOpen(false)}
      />
    </Modal.Actions>
  </Modal>
)

export default TriggerableFullScreenModal
