import React from 'react'
import { withRouter } from 'react-router-dom'
import { Select, Input, Segment } from 'semantic-ui-react'

import './SearchByProperty.css'

const SearchByProperty = props => {
  const {
    isLoading,
    textQuery,
    textQueryField,
    textQueryFieldOptions,
    location,
    history,
  } = props

  const handleUpdate = ({ value, name }) => {
    const query = new URLSearchParams(location.search)

    if (name === 'textQuery' && value === '') {
      query.delete('textQuery')
    } else {
      query.set(name, value)
    }

    /* go back to page 1 when changing filters */
    query.set('skip', 0)

    history.replace(`?${query.toString()}`)
  }

  const onKeyDownHandler = e => {
    if (e.key === 'Enter') {
      handleUpdate({ name: 'textQuery', value: e.target.value })
    }
  }

  return (
    <>
      <Segment id='shared-search-segment-style-override'>
        <div>
          <Input
            className='shared-search-input'
            placeholder='Search'
            name='textQuery'
            onBlur={e => handleUpdate({ name: 'textQuery', value: e.target.value })}
            onKeyDown={onKeyDownHandler}
            defaultValue={textQuery}
            disabled={isLoading}
          />
          <Select
            className='shared-search-select'
            options={textQueryFieldOptions}
            name='textQueryField'
            value={textQueryField}
            onChange={(_, { value }) => handleUpdate({ name: 'textQueryField', value })}
            disabled={isLoading}
          />
        </div>
      </Segment>
    </>
  )
}

export default withRouter(SearchByProperty)
