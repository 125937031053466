/* eslint import/prefer-default-export: 0 */

export function downloadFile(filename, text, contenttype) {
  const element = document.createElement('a')
  const file = new Blob([ text ], { type: contenttype })
  element.href = URL.createObjectURL(file)
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}
