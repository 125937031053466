import React, { useState, useEffect } from 'react'
import { Button, Grid, Header, Modal, Image, Divider, Message } from 'semantic-ui-react'
import nexus from '@ospin/nexus'

const IMAGE_TYPE_MAP = {
  description: {
    TYPE: 'description',
    KEY: 'imageURL',
    TEXT: 'The Description Image',
  },
  icon: {
    TYPE: 'icon',
    KEY: 'iconURL',
    TEXT: 'The Device Icon, used in the Ultimate Builder',
  },
  inputDescription: {
    TYPE: 'inputDescription',
    KEY: 'inputDescriptionImageURL',
    TEXT: 'The Input Description, used in the Information Modal within the Ultimate Builder',
  },
}

export default function FunctionalityDescriptionImageUploadModal({
  show,
  close,
  description,
  updateDescriptionInPlace,
}) {
  const [ imageType, setImageType ] = useState(IMAGE_TYPE_MAP.icon.TYPE)
  const [ loading, setLoading ] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [ preview, setPreview ] = useState(null)
  const [ error, setError ] = useState(null)
  const [ imageHash, setImageHash ] = useState(Date.now())

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  async function setFile(e) {
    setSelectedFile(e.target.files[0])
  }

  const fileToBase64 = async file => new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
  })

  async function handleOnSubmit() {
    try {
      setLoading(true)
      setError(null)
      const imageDataUri = await fileToBase64(selectedFile)
      const {
        data: { functionalityDescription },
      } = await nexus.deviceDescription.functionalityDescription.putImage(
        description.id, { type: imageType, imageDataUri },
      )
      updateDescriptionInPlace(description.id, functionalityDescription)
      setSelectedFile(null)
      setImageHash(Date.now())
    } catch ({ message }) {
      setError(message)
    }

    setLoading(false)

  }

  const renderPreview = () => (preview ? (
    <>
      <Header>Selected Replacement Image</Header>
      <Image fluid alt='uploaded preview' src={preview} />
    </>
  ) : <Header>No Image Selected</Header>)

  const renderCurrent = () => {
    const currentImageUrl = description[IMAGE_TYPE_MAP[imageType].KEY]
    if (!currentImageUrl) {
      return <Header>No Image Uploaded</Header>
    }

    return (
      <>
        <Header>Current Image</Header>
        <Image fluid src={`${currentImageUrl}?${imageHash}`} />
      </>
    )
  }

  return (
    <Modal
      onClose={close}
      open={show}
    >
      <Modal.Header>{`Upload a new Image for ${description.subType}`}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Button.Group fluid>
            <Button
              positive={imageType === IMAGE_TYPE_MAP.icon.TYPE}
              onClick={() => setImageType(IMAGE_TYPE_MAP.icon.TYPE)}
              content='Icon'
            />
            <Button.Or />
            <Button
              positive={imageType === IMAGE_TYPE_MAP.description.TYPE}
              onClick={() => setImageType(IMAGE_TYPE_MAP.description.TYPE)}
              content='Description'
            />
            <Button.Or />
            <Button
              positive={imageType === IMAGE_TYPE_MAP.inputDescription.TYPE}
              onClick={() => setImageType(IMAGE_TYPE_MAP.inputDescription.TYPE)}
              content='Input Description'
            />
          </Button.Group>
          <Divider />
          <Message content={IMAGE_TYPE_MAP[imageType].TEXT} />
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                {renderCurrent()}

              </Grid.Column>
              <Grid.Column>
                {renderPreview()}

              </Grid.Column>
            </Grid.Row>
          </Grid>
          {error ? <Message error content={error} /> : null}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content='Close' onClick={close} />
        <label>
          <input onChange={setFile} type='file' />
          <div className={`ui button ${loading ? 'disabled' : null}`}>
            { selectedFile ? selectedFile.name : 'Select File'}
          </div>
        </label>
        <Button
          loading={loading}
          primary
          disabled={!selectedFile}
          content='Submit'
          onClick={() => handleOnSubmit()}
        />


      </Modal.Actions>
    </Modal>
  )
}
