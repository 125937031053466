import React from 'react'
import { Pagination } from 'semantic-ui-react'
import { generatePaginationSentence } from '~/utils/pagination'
import './UsersTable.css'

const UsersControlPagination = ({
  activePage,
  totalUsers,
  totalPages,
  usersPerPage,
  updateActivePage,
  isLoading,
}) => (
  <>
    {totalUsers > 0 ? (
      <div>
        <Pagination
          disabled={isLoading}
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={updateActivePage}
        />
        <span className='users-table-pagination-sentence'>
          {generatePaginationSentence(activePage, usersPerPage, totalUsers)}
        </span>
      </div>
    ) : null}
  </>
)

export default UsersControlPagination
