export default class User {

  static get ROLES() {
    return {
      admin: 'admin',
      developer: 'developer',
      user: 'user',
    }
  }

  static get NON_ADMIN_ROLES() {
    const roles = { ...User.ROLES }
    delete roles.admin

    return roles
  }

  static get NON_USER_ROLES() {
    const roles = { ...User.ROLES }
    delete roles.user

    return roles
  }

  static get NON_ADMIN_ROLE_NAMES() { return Object.values(User.NON_ADMIN_ROLES) }

  static get ROLE_NAMES() {
    return Object.values(User.ROLES)
  }

  static getRoleHierarchy(role) {
    const hierarchy = {
      [User.ROLES.admin]: [
        User.ROLES.admin,
      ],
      [User.ROLES.developer]: [
        User.ROLES.admin,
        User.ROLES.developer,
      ],
      [User.ROLES.user]: [
        User.ROLES.admin,
        User.ROLES.developer,
        User.ROLES.user,
      ],
    }

    return hierarchy[role] || []
  }

  static isRole({ role: userRole }, targetRole) {
    return targetRole === User.ROLES[userRole]
  }

  static isAdmin(user) { return User.isRole(user, User.ROLES.admin) }

  static isDeveloper(user) { return User.isRole(user, User.ROLES.developer) }

  static isUser(user) { return User.isRole(user, User.ROLES.user) }

  static isRoleOrGreater({ role: userRole }, targetRole) {
    const allowedRoles = User.getRoleHierarchy(targetRole)
    return allowedRoles.includes(userRole)
  }

  static hasMacrophageAccess(user) {
    return User.isRoleOrGreater(user, User.ROLES.developer)
  }

}
