/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Button, Header, Modal } from 'semantic-ui-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import nexus from '@ospin/nexus'
import TriggerableFullScreenModal from '~/components/shared/hocs/modals/TriggerableFullScreenModal'
import { downloadFile } from '~/utils/download'

const FILE_NAMES = {
  certificatePem: 'deviceCertificate.crt',
  privateKey: 'privateKey.key',
  publicKey: 'publicKey.key',
}

const RenderInteractiveCertificateData = (certificateData, deviceName) => {
  const [ lastCopied, setLastCopied ] = React.useState('')

  return (
    Object.entries(certificateData).map(([ dataType, content ]) => (
      <React.Fragment key={dataType}>
        <Header>{ dataType }</Header>
        <Button onClick={() => downloadFile(FILE_NAMES[dataType], content, 'text/plain')}>
          <div>
            Download file
          </div>
        </Button>
        <CopyToClipboard text={content} onCopy={() => setLastCopied(dataType)}>
          <Button>
            <div>
              { lastCopied === dataType ? 'copied!' : `copy ${dataType} to clipboard` }
            </div>
          </Button>
        </CopyToClipboard>
      </React.Fragment>
    ))
  )
}

const GetDeviceCertificateModal = ({
  open,
  setOpen,
  deviceName,
  deviceId,
  setErrorModalOpen,
  setErrorModalText,
}) => {

  const [ loading, setLoading ] = React.useState(false)
  const [ closeButtonDisabled, setCloseButtonDisabled ] = React.useState(true)
  const [ certificateData, setCertificateData ] = React.useState({})

  useEffect(() => {
    (async () => {
      setLoading(true)

      try {
        const { data } = await nexus.device.certificate.get(deviceId)
        setCertificateData(data)
        setLoading(false)
        setCloseButtonDisabled(false)
      } catch (e) {
        setErrorModalOpen(true)
        setErrorModalText(`Get Certificate: ${e.status} - ${e.message}`)
        setOpen(false)
      }

    })()
  }, [])

  const renderContent = () => (
    <Modal.Content>
      <Modal.Description>
        { RenderInteractiveCertificateData(certificateData, deviceName) }
      </Modal.Description>
    </Modal.Content>
  )

  return (
    <TriggerableFullScreenModal
      open={open}
      setOpen={setOpen}
      titleText={`${deviceName} Certificate`}
      loading={loading}
      closeButtonText='close'
      closeButtonDisabled={closeButtonDisabled}
      renderContent={renderContent}
    />
  )
}

export default GetDeviceCertificateModal
