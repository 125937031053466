import React from 'react'

import { Table, Header } from 'semantic-ui-react'
import ZoomableDeviceProperty from '~/components/shared/ZoomableDeviceProperty'
import ZoomableUserProperty from '~/components/shared/ZoomableUserProperty'
import DeviceActionButtons from '~/components/devices/sections/DevicesList/DeviceListItem/DeviceActionButtons'
import SerialNumberInput from './SerialNumberInput'
import MacAddressesSection from './MacAddressesSection'

const DeviceRow = ({ device, removeDeviceById, updateDevice }) => {

  const colorCodeFeatureVer = featureVersion => (
    [null, 'bgRed', 'bgYellow', 'bgGreen'][featureVersion] || 'bgRed'
  )

  return (
    <Table.Row key={device.id} positive={device.newlyCreated}>
      <Table.Cell
        content={
          <ZoomableDeviceProperty property={device.name} deviceId={device.id} />
        }
      />
      <Table.Cell content={device.id} />
      <Table.Cell className={colorCodeFeatureVer(device.featureVersion)} content={device.featureVersion} data-testid='feature-version-number' />
      <Table.Cell content={device.firmwareVersion || '-'} />
      <Table.Cell content={device.online ? <Header as='h4' color='green'>Online</Header> : <Header as='h4' color='grey'>Offline</Header>} />
      <Table.Cell content={device.lastActiveStage} />
      <Table.Cell content={device.owner ? <ZoomableUserProperty property={device.owner.userName} userId={device.owner.id} /> : '-'} />
      <Table.Cell
        content={<SerialNumberInput device={device} updateDevice={updateDevice} />}
      />
      <Table.Cell
        content={<MacAddressesSection device={device} updateDevice={updateDevice} />}
      />
      <Table.Cell content={(
        <DeviceActionButtons
          removeDeviceById={removeDeviceById}
          device={device}
        />
      )}
      />
    </Table.Row>
  )
}

export default DeviceRow
