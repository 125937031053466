import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { useLogout } from '~/contexts/active-user/active-user-context'

const AutoLogout = ({ history }) => {
  const logout = useLogout()

  useEffect(() => {
    history.replace('/logout')
    logout()
  }, [ logout, history ])

  return <></>
}

export default withRouter(AutoLogout)
