import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import ScreenWrapper from '~/components/shared/hocs/ScreenWrapper'
import CreateDevice from '~/components/devices/sections/CreateDevice/CreateDevice'
import DeviceTable from '~/components/devices/sections/DevicesList/DeviceTable'
import { getQueryParams } from '~/utils/query'
import SearchByProperty from '~/components/shared/SearchByProperty'
import DevicesControlPagination from './sections/DevicesList/DevicesControlPagination'

const textQueryFieldOptions = [
  { text: 'device name', value: 'name' },
  { text: 'owner', value: 'ownerName' },
  { text: 'id', value: 'id' },
]

const DEVICES_PER_PAGE = 20
const DEFAULT_QUERY_PARAMS = {
  limit: DEVICES_PER_PAGE,
  skip: 0,
  sortBy: 'name',
  sortOrder: 'ASC',
  textQuery: '',
  textQueryField: 'name',
}

const DevicesScreen = props => {
  const { history, location } = props

  const [devicesWithEmbedOwners, setDevicesWithEmbedOwners] = useState([])
  const [totalDevices, setTotalDevices] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const { textQuery, textQueryField } = getQueryParams(location, DEFAULT_QUERY_PARAMS)

  return (
    <ScreenWrapper fluid screenTitle='Devices'>
      <CreateDevice
        devicesWithEmbedOwners={devicesWithEmbedOwners}
        setDevicesWithEmbedOwners={setDevicesWithEmbedOwners}
      />
      <SearchByProperty
        textQuery={textQuery}
        textQueryField={textQueryField}
        isLoading={isLoading}
        textQueryFieldOptions={textQueryFieldOptions}
      />
      <DevicesControlPagination
        totalDevices={totalDevices}
        isLoading={isLoading}
        defaultQueryParams={DEFAULT_QUERY_PARAMS}
      />
      <DeviceTable
        devicesWithEmbedOwners={devicesWithEmbedOwners}
        setDevicesWithEmbedOwners={setDevicesWithEmbedOwners}
        totalDevices={totalDevices}
        setTotalDevices={setTotalDevices}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        defaultQueryParams={DEFAULT_QUERY_PARAMS}
      />
    </ScreenWrapper>
  )
}

export default withRouter(DevicesScreen)
