import React, { useState, useEffect } from 'react'
import { Form, Select, Button, Input, Message, Checkbox } from 'semantic-ui-react'
import nexus from '@ospin/nexus'
import SectionWrapper from '~/components/shared/hocs/SectionWrapper'
import GetDeviceCertificateModal from '~/components/devices/deviceCertificates/GetDeviceCertificateModal'
import ValidatedFormField from '~/components/shared/hocs/semantic/ValidatedFormField'
import { nonEmptyString } from '~/utils/validators'
import CreateDeviceIcon from './CreateDeviceIcon'

const CreateDevice = ({ devicesWithEmbedOwners, setDevicesWithEmbedOwners }) => {
  const [ fieldValues, setFieldValues ] = useState({ isVirtual: false, name: '' })
  const [ manufacturers, setManufacturers ] = useState([])
  const [ deviceTypes, setDeviceTypes ] = useState([])
  const [ selectedManufacturer, setSelectedManufacturer ] = useState(null)
  const [ selectedDeviceType, setSelectedDeviceType ] = useState(null)
  const [ certModalOpen, setCertModalOpen ] = React.useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ blockedSubmissions, setBlockedSubmissions ] = useState({
    name: true,
    manufacturerDeviceTypeId: true,
  })
  const [ submissionResult, setSubmissionResult ] = useState({ isError: false, data: null })

  useEffect(() => {
    const fetchManufacturers = async () => {
      const { data: fetchedManufacturers } = await nexus.device.manufacturer.list()
      setManufacturers(fetchedManufacturers
        .map(man => ({ text: man.name, value: man.id, key: man.id })))
    }

    fetchManufacturers()
  }, [])

  function setFieldValue(prop, value) {
    setFieldValues({
      ...fieldValues,
      [prop]: value,
    })
  }

  function setBlockedSubmission(prop, bool) {
    setBlockedSubmissions({
      ...blockedSubmissions,
      [prop]: bool,
    })
  }

  async function updateSelectedManufacturer(e, { value: manufacturerId }) {
    const { data: manufacturerDeviceTypes } = await nexus
      .device.manufacturer.deviceType.list({ manufacturerId })
    setDeviceTypes(manufacturerDeviceTypes
      .map(type => ({ text: type.name, value: type.id, key: type.id })))
    setSelectedManufacturer(manufacturerId)
    setBlockedSubmission('manufacturerDeviceTypeId', true)
    setSelectedDeviceType(null)
  }

  async function updateSelectedDeviceType(e, { value: manufacturerDeviceTypeId }) {
    setSelectedDeviceType(manufacturerDeviceTypeId)
    setFieldValue('manufacturerDeviceTypeId', manufacturerDeviceTypeId)
    setBlockedSubmission('manufacturerDeviceTypeId', false)
  }

  async function handleOnSubmit() {
    setLoading(true)

    try {
      const { data: { device } } = await nexus.device.create({
        name: fieldValues.name,
        isVirtual: fieldValues.isVirtual,
        manufacturerDeviceTypeId: fieldValues.manufacturerDeviceTypeId,
      })

      setSubmissionResult({
        isSuccess: true,
        device,
        isError: false,
        errMsg: '',
      })

      setFieldValues({ isVirtual: false, name: '' })
      setSelectedManufacturer(null)
      setSelectedDeviceType(null)
      setCertModalOpen(true)
      addNewDevice(device)
    } catch (e) {
      setSubmissionResult({
        isSuccess: false,
        device: null,
        isError: true,
        errMsg: `${e.status}: ${e.message}`,
      })
    } finally {
      setLoading(false)
    }

  }

  const getDeviceCertificateModal = (deviceId, deviceName) => (
    <GetDeviceCertificateModal
      deviceId={deviceId}
      open={certModalOpen}
      deviceName={deviceName}
      setOpen={setCertModalOpen}
    />
  )

  const addNewDevice = device => {
    /* the newlyCreated key is used just to highlight the row in the table */
    setDevicesWithEmbedOwners([ { ...device, newlyCreated: true }, ...devicesWithEmbedOwners ])
  }

  const submitBlocked = Object.values(blockedSubmissions).some(blocked => blocked)
  const { isSuccess, isError, errMsg, device } = submissionResult

  return (
    <SectionWrapper title='Create Device'>
      { certModalOpen && getDeviceCertificateModal(device.id, device.name) }
      <Form
        className='med-label-text'
        onSubmit={handleOnSubmit}
        success={isSuccess}
        error={isError}
      >
        <Message
          success
          header='Device Created!'
        />
        <Message
          error
          header='Device Creation Failed'
          content={errMsg}
        />
        <ValidatedFormField
          required
          control={Input}
          name='name'
          label='name'
          placeholder='The name of the Device'
          validator={nonEmptyString}
          value={fieldValues.name}
          valErrMsg='Please enter a device name'
          setFieldValue={val => setFieldValue('name', val)}
          blockSubmit={bool => setBlockedSubmission('name', bool)}
        />
        <Form.Field
          required
          control={Select}
          label='Manufacturer'
          placeholder='Select Manufacturer'
          fluid
          options={manufacturers}
          style={{ marginBottom: '16px' }}
          disabled={manufacturers.length === 0}
          loading={manufacturers.length === 0}
          onChange={updateSelectedManufacturer}
          value={selectedManufacturer}
          data-testid='manufacturer-selection-dropdown'
        />
        <Form.Field
          required
          control={Select}
          label='Device Type'
          placeholder='Select Device Type'
          fluid
          options={deviceTypes}
          style={{ marginBottom: '16px' }}
          disabled={selectedManufacturer === null}
          onChange={updateSelectedDeviceType}
          value={selectedDeviceType}
          data-testid='device-type-selection-dropdown'
        />
        <Form.Field>
          <Checkbox
            style={{ fontSize: '15px' }}
            toggle
            name='isVirtual'
            label='this is a virtual device'
            checked={fieldValues.isVirtual}
            onChange={() => setFieldValue('isVirtual', !fieldValues.isVirtual)}
          />
        </Form.Field>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0px' }}>
          <Button
            style={{ width: '50%' }}
            loading={loading}
            icon
            type='submit'
            disabled={submitBlocked}
            data-testid='create-device-btn'
          >
            <CreateDeviceIcon />
          </Button>
        </div>
      </Form>
    </SectionWrapper>
  )
}

export default CreateDevice
