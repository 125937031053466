const getQueryParams = (location, defaults) => {
  const query = new URLSearchParams(location.search)
  const queryValues = [ 'limit', 'skip', 'sortBy', 'sortOrder', 'textQuery', 'textQueryField' ]
  return queryValues.reduce((a, v) => ({ ...a, [v]: query.get(v) === null ? defaults[v] : query.get(v) }), {})
}

const setQueryParametersOnPageLoad = (location, history, defaults) => {
  const query = new URLSearchParams(location.search)
  const queryObject = {}

  const queryParams = Object.keys(defaults)

  queryParams.forEach(param => {
    const value = query.get(param)
    if (value !== null) {
      queryObject[param] = value
    }
  })

  history.replace(`?${new URLSearchParams({ ...defaults, ...queryObject }).toString()}`)
}

const toggleSortOrder = querySortOrder => (
  querySortOrder === 'ASC' ? 'DESC' : 'ASC'
)

const setSortByQueryParamsOnClick = (history, location, sortkey) => {
  const query = new URLSearchParams(location.search)
  const querySortBy = query.get('sortBy')
  const querySortOrder = query.get('sortOrder')

  if (querySortBy === sortkey) {
    query.set('sortOrder', toggleSortOrder(querySortOrder))
  }

  query.set('sortBy', sortkey)

  /* go back to page 1 when changing filters */
  query.set('skip', 0)

  history.replace(`?${query.toString()}`)
}

export {
  getQueryParams,
  setQueryParametersOnPageLoad,
  toggleSortOrder,
  setSortByQueryParamsOnClick,
}
