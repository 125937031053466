import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Message } from 'semantic-ui-react'
import nexus from '@ospin/nexus'
import SectionLoader from '~/components/loaders/SectionLoader'
import {
  setQueryParametersOnPageLoad,
  getQueryParams,
} from '~/utils/query'
import DeviceRow from './DeviceRow'
import DeviceTableHeader from './DeviceTableHeader'
import './DeviceTable.css'

const DeviceTable = props => {

  const {
    devicesWithEmbedOwners,
    setDevicesWithEmbedOwners,
    totalDevices,
    setTotalDevices,
    isLoading,
    setIsLoading,
    location,
    history,
    defaultQueryParams,
  } = props

  const queryParams = getQueryParams(location, defaultQueryParams)

  const { skip, limit, sortBy, sortOrder, textQuery, textQueryField } = queryParams

  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    const fetchDevices = async () => {
      const query = {
        embedOwners: true,
        limit,
        skip,
        sortBy,
        sortOrder,
      }

      if (textQuery && textQueryField) {
        query.textQuery = textQuery
        query.textQueryField = textQueryField
      }

      setIsLoading(true)

      try {
        const { data } = await nexus.device.list(query)

        const { devices, totalMatchingDevices } = data

        setDevicesWithEmbedOwners(devices)
        setTotalDevices(totalMatchingDevices)
        setIsError(false)
        setErrorMsg('')
      } catch ({ message }) {
        setIsError(true)
        setErrorMsg(message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchDevices()
    setQueryParametersOnPageLoad(location, history, defaultQueryParams)
  }, [skip, sortBy, sortOrder, textQuery, textQueryField])

  if (isLoading) return <SectionLoader />
  if (totalDevices === 0) return <Message content='No devices found' />
  if (isError) return <Message error content={errorMsg} />

  const removeDeviceById = deviceId => {
    const updatedDevList = devicesWithEmbedOwners.filter(device => device.id !== deviceId)
    setDevicesWithEmbedOwners(updatedDevList)
  }

  const updateDevice = (updatedDevice, owner) => setDevicesWithEmbedOwners(
    devicesWithEmbedOwners.map(dev => {
      if (dev.id === updatedDevice.id) return { ...updatedDevice, owner }
      return dev
    }),
  )

  return (
    <div style={{ marginTop: '20px' }}>
      <Table celled structured padded className='top-border-ospin-red font-size-10'>
        <Table.Header>
          <DeviceTableHeader history={history} location={location} />
        </Table.Header>
        <Table.Body>
          {
            devicesWithEmbedOwners
              .map(device =>
                <DeviceRow
                  key={device.id}
                  device={device}
                  removeDeviceById={removeDeviceById}
                  updateDevice={updateDevice}
                />)
          }
        </Table.Body>
      </Table>
    </div>
  )
}

export default withRouter(DeviceTable)
