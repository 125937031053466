import React, { useState } from 'react'
import { Form, Button, Input, Icon, Message } from 'semantic-ui-react'

import nexus from '@ospin/nexus'
import { useActiveUserState } from '~/contexts/active-user/active-user-context'
import SectionWrapper from '~/components/shared/hocs/SectionWrapper'
import ValidatedFormField from '~/components/shared/hocs/semantic/ValidatedFormField'
import { nonEmptyString } from '~/utils/validators'

const ChangelogForm = () => {
  const { user: { id: userId } } = useActiveUserState()
  const [ fieldValues, setFieldValues ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ blockedSubmissions, setBlockedSubmissions ] = useState({ message: true })
  const [ submissionResult, setSubmissionResult ] = useState({ isError: false, msg: null })

  function setFieldValue(prop, value) {
    setFieldValues({
      ...fieldValues,
      [prop]: value,
    })
  }

  function setBlockedSubmission(prop, bool) {
    setBlockedSubmissions({
      ...blockedSubmissions,
      [prop]: bool,
    })
  }

  async function handleOnSubmit() {
    setLoading(true)

    try {
      await nexus.user.notifications.putAll({
        originId: userId,
        sourceName: 'macrophage',
        sourceType: 'user',
        topic: 'changelog',
        message: fieldValues.message,
      })
      setSubmissionResult({
        isSuccess: true,
        isError: false,
        errMsg: '',
      })
    } catch (e) {
      setSubmissionResult({
        isSuccess: false,
        isError: true,
        errMsg: `${e.status}: ${e.message}`,
      })
    } finally {
      setLoading(false)
    }
  }

  const submitBlocked = Object.values(blockedSubmissions).some(blocked => blocked)
  const { isSuccess, isError, errMsg } = submissionResult

  return (
    <Form
      className='med-label-text'
      onSubmit={handleOnSubmit}
      success={isSuccess}
      error={isError}
    >
      <Message
        success
        header='Changelog Created!'
      />
      <Message
        error
        header='Submission Failed'
        content={errMsg}
      />
      <ValidatedFormField
        required
        control={Input}
        name='message'
        label='message'
        placeholder='The Title of the Changelog'
        validator={nonEmptyString}
        valErrMsg='Please enter a title for the changelog, you jibronie'
        setFieldValue={val => setFieldValue('message', val)}
        blockSubmit={bool => setBlockedSubmission('message', bool)}
      />
      <Form.Field
        required
        readOnly
        control={Input}
        label='topic'
        value='changelog'
        className='immutable-form-field'
      />
      <Form.Field
        required
        readOnly
        control={Input}
        label='originId (your user id)'
        value={`${userId}`}
        className='immutable-form-field'
      />
      <Form.Field
        required
        readOnly
        control={Input}
        label='sourceType'
        value='user'
        className='immutable-form-field'
      />
      <Form.Field
        required
        readOnly
        control={Input}
        label='sourceName'
        value='macrophage'
        className='immutable-form-field'
      />
      <Button
        loading={loading}
        primary
        icon
        labelPosition='left'
        type='submit'
        disabled={submitBlocked}
      >
        <Icon name='plus' />
        Submit Changelog
      </Button>
    </Form>
  )
}

const CreateChangelog = () => (
  <SectionWrapper title='Create Changelog'>
    <ChangelogForm />
  </SectionWrapper>
)

export default CreateChangelog
