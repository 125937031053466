import React from 'react'
import AsyncApiComponent from '@asyncapi/react-component'
import '@asyncapi/react-component/styles/default.css'

const COMPONENT_CONFIG = {
  show: {
    schemas: false,
    messages: false,
  },
}

const parseMessages = message => (
  message.oneOf ? message.oneOf : [message]
)

const getMessagesForChannel = channel => ({
  publishMessages: channel.publish ? parseMessages(channel.publish.message) : [],
  subscribeMessages: channel.subscribe ? parseMessages(channel.subscribe.message) : [],
})

const filterMessagesByAPITag = (messages, version) => (
  messages.filter(mes => mes.tags && mes.tags.some(tag => tag.name === version))
)

const mapMessagesToAsyncAPI = messages => ({
  message: messages.length === 1
    ? messages[0]
    : { oneOf: messages },
})

const extractChosenAPIVersion = (schema, version) => {
  const { channels } = schema
  const channelNames = Object.keys(channels)

  const newChannels = channelNames.reduce((res, channelName) => {
    const channel = channels[channelName]
    const { publishMessages, subscribeMessages } = getMessagesForChannel(channel)
    const filteredPubMsgs = filterMessagesByAPITag(publishMessages, version)
    const filteredSubMsgs = filterMessagesByAPITag(subscribeMessages, version)

    if (!filteredPubMsgs.length && !filteredSubMsgs.length) return res

    const update = {}

    if (filteredPubMsgs.length) {
      update.publish = mapMessagesToAsyncAPI(filteredPubMsgs)
    }

    if (filteredSubMsgs.length) {
      update.subscribe = mapMessagesToAsyncAPI(filteredSubMsgs)
    }

    return {
      ...res,
      [channelName]: {
        ...channels[channelName],
        ...update,
      },
    }
  }, {})

  return {
    ...schema,
    channels: newChannels,
    tags: schema.tags.filter(tag => tag.name === version),
  }
}

export default ({ schema, mqttAPIVersion }) => (
  <AsyncApiComponent
    schema={extractChosenAPIVersion(schema, mqttAPIVersion)}
    config={COMPONENT_CONFIG}
  />
)
