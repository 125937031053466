import React, { useState, useEffect } from 'react'
import nexus from '@ospin/nexus'
import { msToShortDate } from '~/utils/timeDisplay'
import ResourceAccess from '~/utils/access/ResourceAccess'
import { Modal, Grid, Card, Divider, Segment, Label } from 'semantic-ui-react'
import TriggerableFullScreenModal from '~/components/shared/hocs/modals/TriggerableFullScreenModal'

const UsersDetailsModal = ({ open, setOpenModal, userId }) => {

  const [ user, setUser ] = useState(null)
  const [ userDevices, setUserDevices ] = useState([])
  const [ userDeviceLicences, setUserDeviceLicences ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ hasError, setHasError ] = useState(false)
  const [ errorMsg, setErrorMsg ] = useState('')

  useEffect(() => {
    (
      async () => {
        try {
          const [
            { data: userDatas },
            { data: { devices } },
            { data: deviceLicences },
          ] = await Promise
            .all([
              nexus.user.get(userId),
              nexus.user.device.list({ userId }),
              nexus.user.licence.list({ userId }),
            ])

          setUser(userDatas)
          setUserDevices(devices)
          setUserDeviceLicences(deviceLicences)

        } catch (e) {
          setHasError(true)
          setErrorMsg(e.message)
        } finally {
          setLoading(false)
        }
      }
    )()
  }, [])

  const renderDeviceOwnerLabel = device => (
    ResourceAccess.isOwnerOf(device, userId) ? <Label key={device.id} size='small' color='blue' content='owner' /> : null
  )

  const renderUserAccessGroupName = userDevice => {
    const userAccessGroup = ResourceAccess.getAccessGroup(userDevice, userId)

    if (!userAccessGroup) return null

    return (
      <Label size='small'>
        {userAccessGroup.name}
      </Label>
    )
  }

  const renderUserDeviceListContent = () => (
    userDevices.map(device => (
      <Grid.Row key={device.id}>
        <Grid.Column>
          <b>{device.name}</b>
        </Grid.Column>
        <Grid.Column>
          <div className='user-table-device-list-label-container'>
            {renderUserAccessGroupName(device, userId)}
            {renderDeviceOwnerLabel(device, userId)}
          </div>
        </Grid.Column>
      </Grid.Row>
    ))
  )

  const renderUserDeviceLicences = (
    deviceId,
    claimedAt,
    expiresAt,
    licenceType,
    key,
  ) => (
    <Segment key={deviceId}>
      <p>
        Name:
        {' '}
        { licenceType.name }
      </p>
      <p>
        Claimed at:
        {' '}
        { msToShortDate(claimedAt) }
      </p>
      <p>
        Expires at:
        {' '}
        { expiresAt !== null ? msToShortDate(expiresAt) : 'Never' }
      </p>
      <p>
        Key:
        {' '}
        { key }
      </p>
    </Segment>

  )

  const renderContent = () => (
    <Modal.Content>
      <Modal.Description>
        <Grid columns='equal'>
          <Grid.Column>
            <Card style={{ width: '100%' }}>
              <Card.Content>
                <Card.Header>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width='12'>
                        { user.userName || '-'}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                </Card.Header>
                <Card.Description>
                  Full Name:
                  {' '}
                  { user.fullName || '-'}
                </Card.Description>
                <Card.Description>
                  Email:
                  {' '}
                  { user.email || '-'}
                </Card.Description>
                <Card.Description>
                  Organization:
                  {' '}
                  { user.organization || '-'}
                </Card.Description>
                <Card.Description>
                  Phone Number:
                  {' '}
                  { user.phoneNumber || '-'}
                </Card.Description>
              </Card.Content>
            </Card>

            <Divider hidden />

            <Card style={{ width: '100%' }}>
              <Card.Content>
                <Card.Header>Licence Informations</Card.Header>
                <Card.Description>
                  {
                    userDeviceLicences.length >= 1
                      ? userDeviceLicences.map(({ id: deviceId, claimedAt, expiresAt, licenceType, key }) => (
                        renderUserDeviceLicences(deviceId, claimedAt, expiresAt, licenceType, key)
                      )) : 'No Licences Found'
                  }
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>

          <Grid.Column>
            <Grid columns='equal'>
              {
                renderUserDeviceListContent()
              }
            </Grid>
          </Grid.Column>
        </Grid>
      </Modal.Description>
    </Modal.Content>
  )

  return (
    <TriggerableFullScreenModal
      open={open}
      setOpen={setOpenModal}
      titleText='User Details'
      closeButtonText='close'
      loading={loading}
      error={hasError}
      errorText={errorMsg}
      renderContent={renderContent}
    />
  )
}

export default UsersDetailsModal
