import React from 'react'

import SimpleTriggerableFullScreenModal from '~/components/shared/modals/SimpleTriggerableFullScreenModal'

// :O
const CHRIS_OR_RACHEL = [
  'https://semantic-ui.com/images/avatar/large/chris.jpg',
  'https://react.semantic-ui.com/images/avatar/large/rachel.png',
][Math.round(Math.random())]

const TriggerableFullScreenErrorModal = ({
  open,
  setOpen,
  headerText,
  textBlock,
}) => (
  <SimpleTriggerableFullScreenModal
    open={open}
    setOpen={setOpen}
    titleText='Oh No!'
    headerText={headerText}
    textBlock={textBlock}
    image={CHRIS_OR_RACHEL}
    closeButtonText='I understand'
  />
)

export default TriggerableFullScreenErrorModal
