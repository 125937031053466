import React, { useState } from 'react'
import DeviceModal from '~/components/shared/modals/DeviceModal'
import './ZoomableDeviceProperty.css'

const ZoomableDeviceProperty = props => {

  const { deviceId, property } = props
  const [ openModal, setOpenModal ] = useState(false)

  return (
    <div>
      <div
        onClick={() => setOpenModal(!openModal)}
        className='zoomable-user-property'
      >
        { property }
      </div>
      {
        openModal ? (
          <DeviceModal
            open={openModal}
            setOpenModal={setOpenModal}
            deviceId={deviceId}
          />
        ) : null
      }
    </div>
  )

}

export default ZoomableDeviceProperty
