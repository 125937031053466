import contextFactory from '~/contexts/helpers/contextFactory'
import Dispatchifier from '~/contexts/helpers/Dispatchifier'
// ACTIONS
import requestAuthenticateActiveUser from '~/contexts/active-user/actions/requestAuthenticateActiveUser'
import autoAuthenticate from '~/contexts/active-user/actions/autoAuthenticate'
import logout from '~/contexts/active-user/actions/logout'

/*******************************************************************************
 * DEFAULT STATE
 ******************************************************************************/
const DEFAULT_ACTIVE_USER_STATE = {
  user: null,
  cognitoUser: null,
  session: null,
  authenticated: false,
  authFailure: null,
  autoAuthComplete: false,
}

/*******************************************************************************
 * REDUCER
 ******************************************************************************/
function activeUserReducer(activeUserState, action) {
  const { type, payload } = action

  switch (type) {
    case 'authenticate': {
      const { cognitoUser, user } = payload
      return {
        ...activeUserState,
        cognitoUser,
        user,
        authenticated: true,
        autoAuthComplete: true,
        authFailure: null,
      }
    }
    case 'autoAuthenticate': {
      const { session, user } = payload
      return {
        ...activeUserState,
        session,
        user,
        authenticated: true,
        autoAuthComplete: true,
        authFailure: null,
      }
    }
    case 'authFailure': {
      const { authFailure } = payload
      return {
        ...activeUserState,
        authFailure,
        authenticated: false,
        autoAuthComplete: true,
      }
    }
    case 'autoAuthFailure': {
      return {
        ...activeUserState,
        authenticated: false,
        autoAuthComplete: true,
      }
    }
    case 'logout': {
      return {
        ...DEFAULT_ACTIVE_USER_STATE,
        autoAuthComplete: true,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

/*******************************************************************************
 * CONTEXT CREATION
 ******************************************************************************/
const {
  ContextProvider: ActiveUserProvider,
  useState: useActiveUserState,
  useDispatch: useActiveUserDispatch,
} = contextFactory({
  defaultState: DEFAULT_ACTIVE_USER_STATE,
  reducer: activeUserReducer,
  contextName: 'ActiveUser',
})

/*******************************************************************************
 * DISPATCHIFY ACTIONS
 ******************************************************************************/
const dispatchifier = new Dispatchifier(useActiveUserDispatch)

const useRequestAuthenticateActiveUser = dispatchifier.dispatchify(requestAuthenticateActiveUser)
const useAutoAuthenticate = dispatchifier.dispatchify(autoAuthenticate)
const useLogout = dispatchifier.dispatchify(logout)

export {
  ActiveUserProvider,
  useActiveUserState,
  useActiveUserDispatch,
  activeUserReducer,
  DEFAULT_ACTIVE_USER_STATE,
  // DISPATCHIFIED ACTIONS
  useRequestAuthenticateActiveUser,
  useAutoAuthenticate,
  useLogout,
}
