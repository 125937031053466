import React from 'react'
import { Header, Image, Modal } from 'semantic-ui-react'

import TriggerableFullScreenModal from '~/components/shared/hocs/modals/TriggerableFullScreenModal'

const renderContent = ({ image, headerText, textBlock }) => (
  <Modal.Content image>
    <Image size='medium' src={image} wrapped />
    <Modal.Description>
      <Header>{ headerText }</Header>
      <p>
        { textBlock }
      </p>
    </Modal.Description>
  </Modal.Content>
)

const SimpleTriggerableFullScreenModal = ({
  open,
  setOpen,
  titleText,
  headerText,
  textBlock,
  image,
  closeButtonText,
}) => (
  <TriggerableFullScreenModal
    open={open}
    setOpen={setOpen}
    titleText={titleText}
    closeButtonText={closeButtonText}
    renderContent={() => renderContent({ image, headerText, textBlock })}
  />
)

export default SimpleTriggerableFullScreenModal
