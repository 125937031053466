import React from 'react'
import { Loader } from 'semantic-ui-react'
import './SectionLoader.css'

const SectionLoader = () => (
  <div className='section-loader'>
    <Loader active />
  </div>
)

export default SectionLoader
