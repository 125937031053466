/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useActiveUserState } from '~/contexts/active-user/active-user-context'

const PrivateRoute = ({ children, ...rest }) => {
  const { authenticated } = useActiveUserState()

  return (
    <Route {...rest}>
      { authenticated ? children : <Redirect to='/login' /> }
    </Route>
  )
}

export default PrivateRoute
