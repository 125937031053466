/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { Form } from 'semantic-ui-react'

const ValidatedFormField = ({ validator, valErrMsg, blockSubmit, setFieldValue, ...rest }) => {
  const [ isError, setIsError ] = useState(false)

  const setAndValidate = ({ target: { value } }) => {
    const validationError = !validator(value)
    blockSubmit(validationError)
    setFieldValue(value)
    setIsError(validationError)
  }

  return (
    <Form.Field
      onChange={setAndValidate}
      {...rest}
      error={isError && {
        content: valErrMsg,
        pointing: 'below',
      }}
    />
  )
}

export default ValidatedFormField
