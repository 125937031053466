import React from 'react'
import { withRouter } from 'react-router-dom'

import ManualLogin from '~/components/authentication/ManualLogin'
import AutoLogin from '~/components/authentication/AutoLogin'
import { useActiveUserState } from '~/contexts/active-user/active-user-context'

const Login = () => {
  const { autoAuthComplete } = useActiveUserState()

  return autoAuthComplete
    ? <ManualLogin />
    : <AutoLogin />
}

export default withRouter(Login)
