import contextFactory from '~/contexts/helpers/contextFactory'

/*******************************************************************************
 * DEFAULT STATE
 ******************************************************************************/
const DEFAULT_DEVICES_STATE = {
  devices: [],
  devicesLoaded: false,
}

/*******************************************************************************
 * REDUCER
 ******************************************************************************/

/*******************************************************************************
 * CONTEXT CREATION
 ******************************************************************************/
const {
  ContextProvider: DevicesProvider,
  useState: useDevicesState,
  useDispatch: useDevicesDispatch,
} = contextFactory({
  defaultState: DEFAULT_DEVICES_STATE,
  contextName: 'Devices',
})

/*******************************************************************************
 * DISPATCHIFY ACTIONS
 ******************************************************************************/

export {
  DevicesProvider,
  useDevicesState,
  useDevicesDispatch,
  DEFAULT_DEVICES_STATE,
}
