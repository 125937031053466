function calcTotalPages(totalItems, itemsPerPage) {
  return Math.ceil(totalItems / itemsPerPage)
}

function calculateSkippedItems(data, itemsPerPage) {
  return ((data.activePage - 1) * itemsPerPage)
}

function generatePaginationSentence(activePage, itemsPerPage, totalItems) {
  const firstDisplayedItem = (activePage - 1) * itemsPerPage

  const itemsOnScreen = (activePage * itemsPerPage) > totalItems
    ? totalItems % itemsPerPage
    : itemsPerPage

  const lastDisplayedItem = firstDisplayedItem + itemsOnScreen
  return `showing ${firstDisplayedItem + 1} - ${lastDisplayedItem} out of ${totalItems}`
}

export {
  calcTotalPages,
  calculateSkippedItems,
  generatePaginationSentence,
}
