import React from 'react'
import { Button, Confirm, Popup } from 'semantic-ui-react'

import TriggerableFullScreenErrorModal from '~/components/shared/modals/TriggerableFullScreenErrorModal'

/* This component has gotten kinda beefy, and very powerful.
 *
 * It provides 3 things:
 * - A button with a confirmation screen (e.g. for a thunk)
 * - The option to put a tooltip/popup on the button (the Popup)
 * - The option of a full screen modal if the confirm callback throws
 *   error TriggerableFullScreenErrorModal
 *
 * This component is best used for such cases:
 * A. User clicks a button
 * B. gets prompted for confirmation/cancel (with customizable text for these options)
 * C.
 *   C.a. then the user gets feedback (by setting the 'catchAndDisplayConfirmErrors'
 *   flag) if it fails in the form of a whole screen modal (the callback must throw!)
 *   OR
 *   C.b. then the callback occurs and does not catch an error if one throws
 */

const ButtonWithConfirm = ({
  size,
  tooltipDisabled,
  catchAndDisplayConfirmErrors,
  circular,
  icon,
  buttonClassName,
  color,
  inverted,
  confirmClassName,
  tooltip,
  tooltipPosition,
  confirmHeader,
  confirmContent,
  confirmText,
  confirmErrorMsgHeader,
  cancelText,
  onConfirm,
  buttonText,
  primary = false,
  disabled = false,
}) => {
  const [ open, setOpen ] = React.useState(false)
  const [ errorModalOpen, setErrorModalOpen ] = React.useState(false)
  const [ errorModalText, setErrorModalText ] = React.useState(null)
  const [ loading, setLoading ] = React.useState(false)

  const safeOnConfirm = async () => {
    try {
      await onConfirm()
    } catch (e) {
      setErrorModalText(e.message)
      setErrorModalOpen(true)
    }
  }

  const handleConfirmClicked = async () => {
    setLoading(true)
    setOpen(false)

    const confirmer = catchAndDisplayConfirmErrors ? safeOnConfirm : onConfirm
    await confirmer()

    setLoading(false)
  }

  return (
    <>
      <TriggerableFullScreenErrorModal
        open={errorModalOpen}
        setOpen={setErrorModalOpen}
        headerText={confirmErrorMsgHeader}
        textBlock={errorModalText}
      />
      <Popup
        disabled={tooltipDisabled}
        content={tooltip}
        position={tooltipPosition}
        trigger={(
          <Button
            primary={primary}
            disabled={disabled || loading}
            loading={loading}
            data-testid={tooltip}
            onClick={() => setOpen(true)}
            size={size}
            circular={circular}
            icon={icon}
            className={buttonClassName}
            color={color}
            inverted={inverted}
          >
            { buttonText }
          </Button>
        )}
      />
      <Confirm
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={handleConfirmClicked}
        className={confirmClassName}
        header={confirmHeader}
        content={confirmContent || 'Are you sure?'}
        confirmButton={confirmText || 'Yes please'}
        cancelButton={cancelText || 'No thank you'}
      />
    </>
  )
}

export default ButtonWithConfirm
