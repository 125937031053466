import React from 'react'
import { Container, Header } from 'semantic-ui-react'

const ScreenWrapper = ({
  screenTitle,
  children,
}) => (
  <div style={{ width: '100%' }}>
    <Header style={{ height: '1em' }} size='huge'>
      {screenTitle}
    </Header>
    <Container style={{ paddingBottom: '25px' }} fluid>
      { children }
    </Container>
  </div>
)

export default ScreenWrapper
