const ALLOWED_NON_PRODUCTION_SUBDOMAINS = new Set([
  'dev',
  'staging',
])

export const getSubDomain = (location = window.location) => {
  const { host } = location
  if (host.includes('localhost')) return 'dev'
  if (host.split('.').length === 2) return ''
  return host.split('.')[0]
}

const getNonProductionEnv = location => {
  const subdomain = getSubDomain(location)

  if (!ALLOWED_NON_PRODUCTION_SUBDOMAINS.has(subdomain)) {
    throw new Error('Unknown deployment environment detected')
  }

  return subdomain
}

export const detectEnv = (location = window.location) => (
  `${location.protocol}//${location.host}` === 'https://ospin-support.com'
    ? 'prod'
    : getNonProductionEnv(location)
)
