import React, { useState, useEffect } from 'react'
import nexus from '@ospin/nexus'
import { msToShortDate } from '~/utils/timeDisplay'
import { getSubDomain } from '~/config/detectEnv'
import ResourceAccess from '~/utils/access/ResourceAccess'
import { Modal, Grid, Card, Divider, Segment, Label, List, Button, Icon } from 'semantic-ui-react'
import TriggerableFullScreenModal from '~/components/shared/hocs/modals/TriggerableFullScreenModal'

const DeviceModal = ({ open, setOpenModal, deviceId }) => {

  const [ device, setDevice ] = useState(null)
  const [ deviceUsers, setDeviceUsers ] = useState([])
  const [ deviceLicences, setDeviceLicences ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ hasError, setHasError ] = useState(false)
  const [ errorMsg, setErrorMsg ] = useState('')

  useEffect(() => {
    (
      async () => {
        try {
          const [
            { data: fetchedDevice },
            { data: { users } },
            { data: { licences } },
          ] = await Promise
            .all([
              nexus.device.get({ deviceId }),
              nexus.user.list({ deviceId }),
              nexus.licence.list({ deviceId, embedTypes: true }),
            ])

          setDevice(fetchedDevice)
          setDeviceUsers(users)
          setDeviceLicences(licences)
        } catch (e) {
          setHasError(true)
          setErrorMsg(e.message)
        } finally {
          setLoading(false)
        }
      }
    )()
  }, [])

  const subdomain = getSubDomain()
  const domain = subdomain === '' ? 'ospin-app.com' : `${subdomain}.ospin-app.com`

  const renderContent = () => (
    <Modal.Content>
      <Modal.Description>
        <Grid columns='equal'>
          <Grid.Column>
            <Card style={{ width: '100%' }}>
              <Card.Content>
                <Card.Header>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width='12'>
                        { device.name }
                      </Grid.Column>
                      <Grid.Column width='4'>
                        <Button
                          icon
                          basic
                          size='mini'
                          as='a'
                          target='_blank'
                          href={`https://${domain}/devices/${deviceId}/access`}
                          data-testid='device-info-modal-redirect-button'
                        >
                          <Icon link name='external alternate' />
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                </Card.Header>
                <Card.Description>
                  ID :
                  {' '}
                  { device.id }
                </Card.Description>
                <Card.Description>
                  Type :
                  {' '}
                  { device.manufacturerDeviceType.name }
                </Card.Description>
                <Card.Description>
                  Feature Version:
                  {' '}
                  {device.featureVersion}
                </Card.Description>
                <Card.Description>
                  Firmware Version:
                  {' '}
                  { device.firmwareVersion || '-'}
                </Card.Description>
                <Card.Description>
                  Serial Number :
                  {' '}
                  { device.serialNumber || '-'}
                </Card.Description>
                <Card.Description>
                  <List>
                    MAC Address :
                    {' '}
                    { device.macAddresses !== undefined ? device
                      .macAddresses.map(macAdd => (
                        <List.Item
                          key={macAdd}
                          content={macAdd}
                        />
                      )) : '-'}
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>

            <Divider hidden />

            <Card style={{ width: '100%' }}>
              <Card.Content>
                <Card.Header>Licence Informations</Card.Header>
                <Card.Description>
                  {
                    deviceLicences.map(({
                      claimedAt,
                      expiresAt,
                      licenceType,
                      id,
                      key,
                    }) => (
                      <Segment key={id}>
                        <p>
                          Name:
                          {' '}
                          {licenceType.name}
                        </p>
                        <p>
                          Claimed at:
                          {' '}
                          { claimedAt !== null ? msToShortDate(claimedAt) : 'Never' }
                        </p>
                        <p>
                          Expires at:
                          {' '}
                          { expiresAt !== null ? msToShortDate(expiresAt) : 'Never' }
                        </p>
                        <p>
                          Key:
                          {' '}
                          {key}
                        </p>
                      </Segment>
                    ))
                  }
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>

          <Grid.Column>
            <Grid columns='equal'>
              {
                deviceUsers.map(deviceUser => (
                  <Grid.Row key={deviceUser.id}>
                    <Grid.Column>
                      <span>{deviceUser.userName}</span>
                      {
                        device.ownerId === deviceUser.id
                          ? <Label content='Device Owner' color='blue' />
                          : null
                      }
                    </Grid.Column>
                    <Grid.Column>
                      <Label
                        content={ResourceAccess.getAccessGroup(device, deviceUser.id).name}
                        basic
                      />
                    </Grid.Column>
                  </Grid.Row>
                ))
              }
            </Grid>
          </Grid.Column>
        </Grid>
      </Modal.Description>
    </Modal.Content>
  )

  return (
    <TriggerableFullScreenModal
      open={open}
      setOpen={setOpenModal}
      titleText='Device Info'
      closeButtonText='close'
      loading={loading}
      error={hasError}
      errorText={errorMsg}
      renderContent={renderContent}
    />
  )
}

export default DeviceModal
