const getAccessGroup = (resource, userId) => {
  return resource.access.find(group => group.users.includes(userId))
}

function isOwnerOf(resource, userId) {
  return resource.ownerId === userId
}

function addUserToGroup(resource, groupName, userId) {
  return resource.access.map(group => {
    if (group.name === groupName) {
      return { ...group, users: [ ...group.users, userId ] }
    }
    return group
  })
}

export default {
  getAccessGroup,
  isOwnerOf,
  addUserToGroup,
}
