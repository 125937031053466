import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Icon, Message } from 'semantic-ui-react'
import UserTableRow from '~/components/users/sections/UsersTable/UserTableRow'
import SectionLoader from '~/components/loaders/SectionLoader'
import { setQueryParametersOnPageLoad } from '~/utils/query'
import nexus from '@ospin/nexus'

const usersTableHeaders = [
  'username',
  'full name',
  'email',
  'role',
  'id',
  'devices',
  ' ',
]

const clickableHeaders = ['userName', 'fullName']

const mapHeaderToSortBy = header => {
  const mapping = {
    username: 'userName',
    'full name': 'fullName',
  }
  return mapping[header]
}

const UsersTable = props => {

  const {
    location,
    history,
    limit,
    skip,
    sortBy,
    sortOrder,
    textQuery,
    textQueryField,
    usersWithEmbedDevices,
    setUsersWithEmbedDevices,
    setTotalUsers,
    setSortByQueryParamsOnClick,
    totalUsers,
    isLoading,
    setIsLoading,
    defaultQueryParams,
  } = props

  const updateUserRole = (userId, newRole) => {
    const updatedUsers = usersWithEmbedDevices.map(user => {
      if (user.id === userId) {
        return { ...user, role: newRole }
      }
      return user
    })
    setUsersWithEmbedDevices(updatedUsers)
  }

  const getClassNameForHeader = header => {
    const pointerCursorClass = 'users-table-clickable-header'
    const highlightedHeaderClass = 'users-table-active-sortable-header'
    const query = new URLSearchParams(location.search)
    const querySortBy = query.get('sortBy')

    if (clickableHeaders.includes(header)) {
      if (header === querySortBy) {
        return `${pointerCursorClass} ${highlightedHeaderClass}`
      }

      return pointerCursorClass
    }
  }

  const getSortIcon = header => {
    const query = new URLSearchParams(location.search)
    const querySortBy = query.get('sortBy')
    const querySortOrder = query.get('sortOrder')

    if (header === querySortBy) {
      if (querySortOrder === 'ASC') {
        return 'sort ascending'
      }

      return 'sort descending'
    }
  }

  useEffect(() => {
    const fetchUsers = async () => {
      const query = {
        embedDevices: true,
        limit,
        skip,
        sortBy,
        sortOrder,
      }

      if (textQuery && textQueryField) {
        query.textQuery = textQuery
        query.textQueryField = textQueryField
      }

      setIsLoading(true)
      try {
        const { data } = await nexus.user.list(query)

        const { users, totalMatchingUsers } = data
        setUsersWithEmbedDevices(users)
        setTotalUsers(totalMatchingUsers)
      } catch (_) {
        // TODO: add error handling
      } finally {
        setIsLoading(false)
      }
    }

    fetchUsers()
    setQueryParametersOnPageLoad(location, history, defaultQueryParams)
  }, [skip, sortBy, sortOrder, textQuery, textQueryField])

  if (isLoading) return <SectionLoader />

  if (totalUsers === 0) { return <Message content='No users found' /> }

  return (
    <Table celled structured padded className='top-border-ospin-red font-size-10'>
      <Table.Header>
        <Table.Row>
          {usersTableHeaders.map(header => (
            <Table.HeaderCell
              key={header}
              onClick={() => setSortByQueryParamsOnClick(mapHeaderToSortBy(header))}
              className={getClassNameForHeader(mapHeaderToSortBy(header))}
              data-testid={`users-table-${mapHeaderToSortBy(header)}-header`}
            >
              {header}
              <Icon
                name={getSortIcon(mapHeaderToSortBy(header))}
              />
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {usersWithEmbedDevices.map(user => (
          <UserTableRow
            key={user.id}
            user={user}
            updateUserRole={updateUserRole}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

export default withRouter(UsersTable)
