import React from 'react'

import UnAuthenticatedRoutes from '~/components/routing/UnAuthenticatedRoutes'

const UnAuthenticatedApp = () => (
  <div data-testid='unauthenticated-app'>
    <UnAuthenticatedRoutes />
  </div>
)

export default UnAuthenticatedApp
