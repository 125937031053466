import React from 'react'
import { Image, Loader } from 'semantic-ui-react'
import logo3 from '~/images/logo3.png'

const OspinLogoLoader = () => (
  <div>
    <Image
      src={logo3}
      centered
      size='medium'
    />
    <Loader active inline='centered' size='medium' />
  </div>
)

export default OspinLogoLoader
