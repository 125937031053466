import React from 'react'

import CreateChangelog from '~/components/admin/sections/CreateChangelog'
import ScreenWrapper from '~/components/shared/hocs/ScreenWrapper'

const AdminScreen = () => (
  <ScreenWrapper screenTitle='Admin'>
    <CreateChangelog />
  </ScreenWrapper>
)

export default AdminScreen
