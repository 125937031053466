import nexus from '@ospin/nexus'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Input, Message } from 'semantic-ui-react'
import ScreenWrapper from '~/components/shared/hocs/ScreenWrapper'
import './FunctionalityDescriptionEditor.css'
import ButtonWithConfirm from '~/components/shared/ButtonWithConfirm'
import EditorArea from './EditorArea'
import FunctionalityDescriptionImageUploadModal from './FunctionalityDescriptionImageUploadModal'

const modes = [
  'tree',
  'text',
]

const getModeOptions = () => modes.map(m => ({ text: m, value: m, key: m }))

const mapDescriptionsToOptions = descriptions => descriptions
  .map(desc => ({
    text: desc.subType,
    value: desc.id,
    key: desc.id,
  }))
  .sort((a, b) => a.text.localeCompare(b.text))

const renderErrorMessage = error => {
  if (error === null) return null
  return <Message error content={error} />
}

const FunctionalityDescriptionEditor = () => {

  const [selectedDescId, setSelectedDescId] = useState(null)
  const [creatingDescription, setCreatingDescription] = useState(false)
  const [deletingDescription, setDeletingDescription] = useState(false)
  const [updatingDescription, setUpdatingDescription] = useState(false)
  const [ showImageUploadModal, setShowImageUploadModal ] = useState(false)
  const [subType, setSubType] = useState('')
  const [descriptions, setDescriptions] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState(modes[0])

  /* the editor resets a lot when we render the description directly from the descriptions array
  /* which makes it close to unusable, so we store a copy of the description
  /* that we store our changes to, and sync it with the one in the array on save;
  /* because the editor is uncontrolled, it neither renders from the descriptions array
  /* nor the tempDescription now, but the tempDescription is used as an initial value, and
  /* we overwrite what the editor renders everytime when the selected description or
  /* the description array changes.
  */
  const [tempDescription, setTempDescription] = useState(null)
  const [hasValidationError, setValidationError] = useState(false)

  useEffect(async () => {
    setLoading(true)
    const { data: { functionalityDescriptions } } = await nexus
      .deviceDescription.functionalityDescription.list()
    setDescriptions(functionalityDescriptions)
    setLoading(false)
  }, [])

  const createDescription = async () => {
    setCreatingDescription(true)
    setError(null)

    try {
      const { data: { functionalityDescription } } = await nexus
        .deviceDescription.functionalityDescription
        .create({ functionalityDescription: { subType } })
      setDescriptions([ ...descriptions, functionalityDescription ])
      setSelectedDescId(functionalityDescription.id)
      setSubType('')
    } catch ({ message }) {
      setError(message)
    } finally {
      setCreatingDescription(false)
    }
  }

  useEffect(() => {
    const tempDesc = selectedDescId !== null
      ? descriptions.find(desc => desc.id === selectedDescId)
      : null
    setTempDescription(tempDesc)
  }, [selectedDescId, descriptions])

  const deleteDescription = async () => {
    setDeletingDescription(true)
    setError(null)

    try {
      await nexus
        .deviceDescription.functionalityDescription
        .remove(selectedDescId)
      const filteredDescriptions = descriptions
        .filter(desc => desc.id !== selectedDescId)
      setDescriptions(filteredDescriptions)
      setSelectedDescId(null)
    } catch ({ message }) {
      setError(message)
    } finally {
      setDeletingDescription(false)
    }
  }

  const renderCreationForm = () => (
    <div>
      <Input
        className='functionality-description-create-input'
        placeholder='enter subType'
        value={subType}
        onChange={({ target: { value } }) => setSubType(value)}
      />
      <Button
        primary
        disabled={subType === '' || creatingDescription}
        loading={creatingDescription}
        onClick={createDescription}
      >
        Create
      </Button>
    </div>
  )

  const updateDescriptionInPlace = (id, updatedDescription) => {
    setDescriptions(descriptions.map(desc => {
      if (desc.id === id) return updatedDescription
      return desc
    }))
  }

  const saveDescription = async () => {

    setUpdatingDescription(true)
    setError(null)

    const { id, subType: _, ...updateData } = tempDescription

    try {
      const { data: { functionalityDescription } } = await nexus
        .deviceDescription.functionalityDescription
        .update(selectedDescId, { updateData })

      updateDescriptionInPlace(id, functionalityDescription)

    } catch ({ message }) {
      setError(message)
    } finally {
      setUpdatingDescription(false)
    }
  }

  const onValidationError = errors => {
    /* using "hasValidationError" to optimize this does not work because the editor
    /* creates a closure, meaning "hasValidationError" remains "false" forever for it. */
    if (errors.length) {
      setValidationError(true)
    } else {
      setValidationError(false)
    }
  }

  const description = descriptions.find(desc => desc.id === selectedDescId)

  return (
    <ScreenWrapper screenTitle='Functionality Description Editor'>
      { showImageUploadModal
        ? (
          <FunctionalityDescriptionImageUploadModal
            show={showImageUploadModal}
            close={() => setShowImageUploadModal(false)}
            description={descriptions.find(({ id }) => id === selectedDescId)}
            updateDescriptionInPlace={updateDescriptionInPlace}
          />
        )
        : null }
      <div className='functionality-description-header'>
        <div>
          <Dropdown
            selection
            search
            loading={loading}
            disabled={loading}
            className='functionality-description-dropdown'
            value={selectedDescId}
            options={mapDescriptionsToOptions(descriptions)}
            placeholder='Select Description'
            onChange={(_, { value }) => setSelectedDescId(value)}
          />
          <ButtonWithConfirm
            primary
            buttonText='Delete'
            tooltipDisabled
            disabled={selectedDescId === null || deletingDescription}
            loading={deletingDescription}
            onConfirm={deleteDescription}
          />
        </div>
        {renderCreationForm()}
      </div>
      {renderErrorMessage(error)}
      { selectedDescId ? (
        <>
          <div className='functionality-description-editor-header'>
            <Button
              primary
              disabled={updatingDescription || hasValidationError}
              loading={updatingDescription}
              onClick={saveDescription}
              content='Save'
            />
            <Button
              onClick={() => setShowImageUploadModal(true)}
              content='Images'
            />
            <Dropdown
              className='functionality-description-editor-mode-selection'
              selection
              value={mode}
              options={getModeOptions()}
              onChange={(_, { value }) => setMode(value)}
            />
          </div>
          <div className='functionality-description-editor-wrapper'>
            <EditorArea
              description={description}
              tempDescription={tempDescription}
              updateDescription={setTempDescription}
              onValidationError={onValidationError}
              mode={mode}
            />
          </div>
        </>
      ) : null }

    </ScreenWrapper>
  )
}

export default FunctionalityDescriptionEditor
