import React from 'react'
import Main from '~/components/Main'
import { ActiveUserProvider } from '~/contexts/active-user/active-user-context'

const App = () => (
  <ActiveUserProvider>
    <Main />
  </ActiveUserProvider>
)

export default App
