import React, { useState, useEffect } from 'react'
import nexus from '@ospin/nexus'
import { Loader, Message } from 'semantic-ui-react'
import LicenceTypeTable from './LicenceTypeTable'

export default function LicenceTypeScreen() {
  const [loading, setLoading] = useState(true)
  const [licenceTypes, setLicenceTypes ] = useState([])

  const [ error, setError ] = useState(null)
  useEffect(() => {
    async function fetchLicenceTypes() {
      try {
        const { data } = await nexus.licence.type.list({ embedIncludedTemplates: true })
        setLicenceTypes(data)

      } catch ({ message }) {
        setError(message)
      } finally {
        setLoading(false)

      }
    }
    fetchLicenceTypes()
  }, [])

  if (loading) { return <Loader active/> }

  if (error) { return <Message error content={error}/> }

  return <LicenceTypeTable licenceTypes={licenceTypes}/>
}
