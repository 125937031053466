import nexus from '@ospin/nexus'
import React, { useState } from 'react'
import { Modal, Input, Button, Message } from 'semantic-ui-react'
import TriggerableFullScreenModal from '~/components/shared/hocs/modals/TriggerableFullScreenModal'
import { isUUID } from '~/utils/validators'
import './LinkLicenceModal.css'

const getLinkedDeviceById = async deviceId => {
  const { data: device } = await nexus.device.get({ deviceId })
  return device
}

const LinkLicenceModal = props => {
  const {
    licence,
    setOpenLinkLicenceModal,
    open,
    updateLicenceRow,
  } = props

  const [deviceId, setDeviceId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isValidationError, setValidationError] = useState(false)

  const handleValue = value => {
    setDeviceId(value)
    setValidationError(false)
  }

  const submitLinkLicence = async () => {
    const { key: licenceKey } = licence

    if (!isUUID(deviceId)) {
      setValidationError(true)
      return
    }

    setIsLoading(true)
    try {
      await nexus.licence.preAssign({ key: licenceKey, deviceId })

      const device = await getLinkedDeviceById(deviceId)
      setIsError(false)
      setErrorMsg('')
      setIsLoading(false)
      updateLicenceRow({ ...licence, device })
    } catch ({ message }) {
      setIsError(true)
      setErrorMsg(message)
      setIsLoading(false)
    }
  }

  const renderContent = () => (
    <Modal.Content>
      <Modal.Description>
        <Input
          defaultValue={deviceId}
          className='link-licence-modal-input'
          placeholder='Enter a device id'
          onChange={({ target }) => handleValue(target.value)}
        />
        <Button
          primary
          onClick={() => submitLinkLicence()}
          loading={isLoading}
          disabled={isLoading}
        >
          Submit
        </Button>
        {isValidationError && (
          <Message
            negative
            content='Please enter a valid id'
          />
        )}
      </Modal.Description>
    </Modal.Content>
  )

  return (
    <TriggerableFullScreenModal
      open={open}
      setOpen={setOpenLinkLicenceModal}
      titleText='Link licence'
      closeButtonText='close'
      error={isError}
      errorText={errorMsg}
      renderContent={renderContent}
    />
  )
}

export default LinkLicenceModal
