import React, { useState } from 'react'
import UsersDetailsModal from '~/components/shared/modals/UsersDetailsModal'
import './ZoomableUserProperty.css'

const ZoomableUserProperty = props => {

  const { userId, property } = props
  const [ openModal, setOpenModal ] = useState(false)

  return (
    <div>
      <div
        onClick={() => setOpenModal(true)}
        className='zoomable-user-property'
      >
        { property }
      </div>
      {
        openModal ? (
          <UsersDetailsModal
            open={openModal}
            setOpenModal={setOpenModal}
            userId={userId}
          />
        ) : null
      }
    </div>
  )

}

export default ZoomableUserProperty
