import React from 'react'
import { Button, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

function generateLicenceTypeRows(licenceType) {
  const { name: licenceTypeName, templates } = licenceType

  return templates.map(({ name, id }, idx) => (
    <Table.Row key={id}>
      {idx === 0 ? <Table.Cell rowSpan={templates.length} content={licenceTypeName} /> : null}
      <Table.Cell>
        <Link to={`/functionality-graph-templates?templateId=${ id }`}>
          <Button fluid content={name} />
        </Link>
      </Table.Cell>
      <Table.Cell>{id}</Table.Cell>
    </Table.Row>
  ))
}

function LicenceTypeTable({ licenceTypes }) {
  return (
    <Table celled structured>
      <Table.Header>
        <Table.Row >
          <Table.HeaderCell rowSpan={2}>Licence Type Name</Table.HeaderCell>
          <Table.HeaderCell colSpan={2}>Included Templates</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Template Name</Table.HeaderCell>
          <Table.HeaderCell>Template Id</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>

        {licenceTypes.map(generateLicenceTypeRows)}
      </Table.Body>
    </Table>
  )
}

export default LicenceTypeTable
