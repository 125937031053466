import React from 'react'
import { Icon } from 'semantic-ui-react'

// The double assignment of size is a hacky way to make the small
// icon have a larger ratio
const CreateDeviceIcon = () => (
  <Icon.Group size='huge'>
    <Icon circular color='green' size='small' name='hdd' />
    <Icon corner color='green' name='add' />
  </Icon.Group>
)

export default CreateDeviceIcon
