import React, { useState } from 'react'
import { Segment, Image, Form, Grid, Container, Divider, Button, Message } from 'semantic-ui-react'

import useSendValueTo from '~/utils/syntheticEventHelpers/useSendValueTo'
import { useRequestAuthenticateActiveUser, useActiveUserState } from '~/contexts/active-user/active-user-context'
import logo3 from '~/images/logo3.png'

const ManualLogin = () => {
  const [ username, setUsername ] = useState(null)
  const [ password, setPassword ] = useState(null)
  const [ pending, setPending ] = useState(false)
  const requestAuthenticateActiveUser = useRequestAuthenticateActiveUser()
  const { authFailure } = useActiveUserState()

  async function submitLogin() {
    setPending(true)
    const { success } = await requestAuthenticateActiveUser(username, password)
    // if it was successful, this component won't render
    // this prevents updating the component after its unmounted
    if (!success) setPending(false)
  }

  return (
    <Container>
      <Grid centered>
        <Grid.Column width={6}>
          <Image src={logo3} centered size='medium' />
          <Divider horizontal hidden />
          <Form error={!!authFailure} size='large' onSubmit={e => e.preventDefault}>
            <Segment>
              <Form.Input
                size='big'
                fluid
                iconPosition='left'
                id='username'
                icon='user'
                placeholder='Your username...'
                onChange={useSendValueTo(setUsername)}
                className='loginInput'
              />
              <Form.Input
                size='big'
                fluid
                iconPosition='left'
                id='password'
                icon='lock'
                type='password'
                placeholder='Your password...'
                onChange={useSendValueTo(setPassword)}
                className='loginInput'
              />
              <Message
                error
                header='Login Failed!'
                content={authFailure}
              />
              <div>
                <Button
                  loading={pending}
                  size='large'
                  primary
                  fluid
                  onClick={submitLogin}
                  id='login'
                  type='submit'
                >
                  Login
                </Button>
              </div>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default ManualLogin
