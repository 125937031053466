/* eslint import/prefer-default-export: 0 */

function replaceBy(key, arr, replacer) {
  return arr.map(existing => (
    existing[key] === replacer[key]
      ? replacer
      : existing
  ))
}

const replaceById = replaceBy.bind(null, 'id')

function removeBy(key, arr, deleter) {
  return arr.filter(existing => (
    existing[key] !== deleter[key]
  ))
}

const removeById = removeBy.bind(null, 'id')

export { replaceById, removeById }
