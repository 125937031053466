import React, { useState, useEffect, createRef } from 'react'
import { Button, Input } from 'semantic-ui-react'
import nexus from '@ospin/nexus'

const isMacAddressValid = value => (
  /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(value)
)

const MacAddressesSection = props => {
  const { device, updateDevice } = props
  const { id, macAddresses, owner } = device

  const [currentMacAddresses, setCurrentMacAddresses] = useState(macAddresses)

  const [macAddressLoadingStates, setMacAddressLoadingStates] = useState(
    Array(macAddresses ? macAddresses.length : 0)
      .fill(null)
      .map(() => ({ isLoading: false })),
  )

  const [macAddressErrorStates, setMacAddressErrorStates] = useState(
    Array(macAddresses ? macAddresses.length : 0)
      .fill(null)
      .map(() => ({ isError: false })),
  )

  useEffect(() => {
    setCurrentMacAddresses(macAddresses)
  }, [macAddresses])

  const setValueByIndex = (array, valueName, value, updateIndex) => (
    array.map((requestState, idx) => (idx === updateIndex
      ? { ...requestState, [valueName]: value } : requestState))
  )

  const setLoaderByIndex = (isLoading, updateIndex) => {
    const newLoadingStates = setValueByIndex(macAddressLoadingStates, 'isLoading', isLoading, updateIndex)
    setMacAddressLoadingStates(newLoadingStates)
  }

  const setErrorByIndex = (isError, updateIndex) => {
    const newErrorStates = setValueByIndex(macAddressErrorStates, 'isError', isError, updateIndex)
    setMacAddressErrorStates(newErrorStates)
  }

  const updateMacAddressByIndex = (currAddresses, newMacAddress, updateIndex) => (
    currAddresses
      .map((macAddress, index) => (index === updateIndex ? newMacAddress : macAddress))
  )

  const updateMacAddress = async (newValue, updateIndex) => {
    if (macAddresses.includes(newValue)) {
      setErrorByIndex(false, updateIndex)
      return
    }

    if (!isMacAddressValid(newValue)) {
      setErrorByIndex(true, updateIndex)
      return
    }

    setLoaderByIndex(true, updateIndex)
    const newMacAddresses = updateMacAddressByIndex(currentMacAddresses, newValue, updateIndex)

    try {
      const { data: updatedDevice } = await nexus.device
        .update({ deviceId: id, params: { macAddresses: newMacAddresses } })

      updateDevice(updatedDevice, owner)
      setErrorByIndex(false, updateIndex)
    } catch (e) {
      setErrorByIndex(true, updateIndex)
    } finally {
      setLoaderByIndex(false, updateIndex)
    }
  }

  const handleDelete = async deleteIndex => {
    const isEmptyAddress = currentMacAddresses[deleteIndex] === ''
    const filteredValues = macAddresses.filter((_, index) => deleteIndex !== index)

    if (isEmptyAddress) {
      setCurrentMacAddresses(filteredValues)
      const updatedErrorStates = macAddressErrorStates.filter((_, index) => deleteIndex !== index)
      setMacAddressErrorStates(updatedErrorStates)
      return
    }

    setLoaderByIndex(true, deleteIndex)

    const { data: updatedDevice } = await nexus.device
      .update({ deviceId: id, params: { macAddresses: filteredValues } })
    updateDevice(updatedDevice, owner)

    const updatedErrorStates = macAddressErrorStates.filter((_, index) => deleteIndex !== index)
    setMacAddressErrorStates(updatedErrorStates)

    const updatedLoadingStates = macAddressLoadingStates.filter((_, index) => deleteIndex !== index)
    setMacAddressLoadingStates(updatedLoadingStates)
  }

  const addNewMacAddress = () => {
    setMacAddressLoadingStates([...macAddressLoadingStates, { isLoading: false }])
    setMacAddressErrorStates([...macAddressErrorStates, { isError: false }])
    setCurrentMacAddresses([...currentMacAddresses, ''])
  }

  const ref = createRef()

  return (
    <>
      <div>
        {currentMacAddresses ? currentMacAddresses.map((address, index) => (
          <div key={`${address}-${index}`} style={{ display: 'flex' }}>
            <Input
              className='device-table-macAddress-input'
              error={macAddressErrorStates[index].isError}
              ref={ref}
              defaultValue={address}
              onBlur={({ target }) => {
                if (target.value.trim() === '') { target.value = address }
                updateMacAddress(target.value, index)
              }}
              data-testid={`mac-address-input-${address}`}
              disabled={macAddressLoadingStates.some(loadingState => loadingState.isLoading)}
            />
            <Button
              compact
              onClick={() => handleDelete(index)}
              icon='trash'
              className='device-table-macAddress-input-delete-button'
              data-testid={`mac-address-delete-btn-${address}`}
              loading={macAddressLoadingStates[index].isLoading}
              disabled={macAddressLoadingStates.some(loadingState => loadingState.isLoading)}
            />
          </div>
        )) : null }
      </div>
      <Button
        compact
        primary
        onClick={addNewMacAddress}
        icon='add'
        className='device-table-macAddress-add-new-input-button'
        data-testid='macAddress-add-new-mac-address-button'
      />
    </>
  )
}

export default MacAddressesSection
