import React from 'react'
import { Modal } from 'semantic-ui-react'
import TriggerableFullScreenModal from '~/components/shared/hocs/modals/TriggerableFullScreenModal'
import SSHAccess from './SSHAccess'

const renderContent = ({ deviceId }) => (
  <Modal.Content>
    <Modal.Description>
      <SSHAccess
        deviceId={deviceId}
      />
    </Modal.Description>
  </Modal.Content>
)

const GetSSHAccess = ({
  open,
  setOpen,
  deviceName,
  deviceId,
}) => (
  <TriggerableFullScreenModal
    open={open}
    setOpen={setOpen}
    titleText={`${deviceName} SSH Access`}
    closeButtonText='close'
    renderContent={() => renderContent({ deviceId })}
  />
)

export default GetSSHAccess
