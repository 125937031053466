import nexus from '@ospin/nexus'

import User from '~/models/User'

export default async function requestAuthenticateActiveUser(dispatch, username, password) {

  try {
    const { data: cognitoUser } = await nexus.auth.signIn(username, password)
    const signInSessionUserSub = cognitoUser.signInUserSession?.accessToken?.payload?.sub
    if (!signInSessionUserSub) throw new Error('No signInSessionUserSub found!')

    try {
      const { data: user } = await nexus.user.get(signInSessionUserSub)
      const authorized = User.hasMacrophageAccess(user)
      const payload = authorized
        ? { type: 'authenticate', payload: { cognitoUser, user } }
        : { type: 'authFailure', payload: { authFailure: `${user.userName} does not have the required access level. User role: ${user.role}` } }

      dispatch(payload)
      return { success: authorized }
    } catch (e) {
      dispatch({ type: 'authFailure', payload: { authFailure: e.message } })
      return { success: false }
    }

  } catch (e) {
    dispatch({ type: 'authFailure', payload: { authFailure: e.message } })
    return { success: false }
  }
}
