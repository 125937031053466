import nexus from '@ospin/nexus'
import React, { useEffect, useState } from 'react'
import { Dropdown, Table } from 'semantic-ui-react'
import ScreenWrapper from '~/components/shared/hocs/ScreenWrapper'
import './FunctionalityDefinitions.css'

const mapDefinitionToOptions = descriptions => descriptions
  .map(desc => ({
    text: desc.subType,
    value: desc.id,
    key: desc.id,
  }))
  .sort((a, b) => a.text.localeCompare(b.text))

const HEADERS = ['subType', 'default name', 'slot', 'type', 'dataType', 'unit']

const renderFctData = (slots, prop) => (
  <Table.Cell rowSpan={slots.length} content={prop} />
)

const renderFunctionalityDefinition = def => {
  if (!def) return null

  return (
    <Table className='top-border-ospin-red' celled structured>
      <Table.Header>
        <Table.Row>
          {HEADERS.map(text => (<Table.HeaderCell key={text} content={text} />))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {def.slots
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((slot, idx) => (
            <Table.Row key={def.id + slot.name}>
              {idx === 0 && renderFctData(def.slots, def.subType)}
              {idx === 0 && renderFctData(def.slots, def.name || '-')}
              <Table.Cell>
                {slot.name}
              </Table.Cell>
              <Table.Cell>
                {slot.type}
              </Table.Cell>
              <Table.Cell>
                {slot.dataType}
              </Table.Cell>
              <Table.Cell>
                {slot.unit}
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  )
}

const FunctionalityDefinitions = () => {

  const [selectedDefId, setSelectedDefId] = useState(null)
  const [definitions, setDefinitions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    setLoading(true)
    const { data: { functionalityDefinitions } } = await nexus
      .deviceDescription.functionalityDefinition.list()
    setDefinitions(functionalityDefinitions)
    setLoading(false)
  }, [])

  const definition = definitions.find(def => def.id === selectedDefId)

  return (
    <ScreenWrapper screenTitle='Functionality Definitions'>
      <div>
        <Dropdown
          selection
          search
          loading={loading}
          disabled={loading}
          className='functionality-definition-dropdown'
          value={selectedDefId}
          options={mapDefinitionToOptions(definitions)}
          placeholder='Select Definition'
          onChange={(_, { value }) => setSelectedDefId(value)}
        />
      </div>
      {renderFunctionalityDefinition(definition)}
    </ScreenWrapper>
  )
}

export default FunctionalityDefinitions
