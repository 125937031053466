import React from 'react'
import SideBar from '~/components/sidebar/SideBar'
import AuthenticatedRoutes from '~/components/routing/AuthenticatedRoutes'
import { DevicesProvider } from '~/contexts/devices/devices-context'
import { UsersProvider } from '~/contexts/users/users-context'

const MainScreen = () => (
  <div id='authenticated-app-main-screen'>
    <AuthenticatedRoutes />
  </div>
)

const AuthenticatedApp = () => (
  <UsersProvider>
    <DevicesProvider>
      <div>
        <SideBar />
        <div id='sidebar-spacer' />
        <MainScreen />
      </div>
    </DevicesProvider>
  </UsersProvider>
)

export default AuthenticatedApp
