import React from 'react'
import { withRouter } from 'react-router-dom'
import { Pagination } from 'semantic-ui-react'
import { calcTotalPages, generatePaginationSentence, calculateSkippedItems } from '~/utils/pagination'
import { getQueryParams } from '~/utils/query'
import './DeviceTable.css'

const DevicesControlPagination = props => {

  const {
    totalDevices,
    isLoading,
    location,
    history,
    defaultQueryParams,
  } = props

  const { skip } = getQueryParams(location, defaultQueryParams)

  const updateActivePage = (_, data) => {
    const query = new URLSearchParams(location.search)
    query.set('skip', calculateSkippedItems(data, defaultQueryParams.limit))

    history.replace(`?${query.toString()}`)
  }

  const activePage = Math.floor(skip / defaultQueryParams.limit) + 1
  const totalPages = calcTotalPages(totalDevices, defaultQueryParams.limit)

  return (
    <>
      {totalDevices > 0 ? (
        <div>
          <Pagination
            disabled={isLoading}
            activePage={activePage}
            totalPages={totalPages}
            onPageChange={updateActivePage}
          />
          <span className='devices-table-pagination-sentence'>
            {generatePaginationSentence(activePage, defaultQueryParams.limit, totalDevices)}
          </span>
        </div>
      ) : null}
    </>
  )
}

export default withRouter(DevicesControlPagination)
