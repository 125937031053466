import React from 'react'
import { msToShortDate } from '~/utils/timeDisplay'

const renderPropLine = (propName, value) => (
  // eslint-disable-next-line
  value && <div>{propName}: <b>{ value }</b></div>
)

const UserDetails = ({
  user: {
    id: userId,
    userName,
    role,
    email,
    fullName,
    organization,
    createdAt,
  },
}) => (
  <>
    { renderPropLine('Username', userName) }
    { renderPropLine('User ID', userId) }
    { renderPropLine('Full Name', fullName) }
    { renderPropLine('Role', role) }
    { renderPropLine('Organization', organization) }
    { renderPropLine('Email', email) }
    { renderPropLine('Created', msToShortDate(createdAt)) }
  </>
)

export default UserDetails
