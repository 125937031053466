import ReactDOM from 'react-dom'
import React from 'react'
import nexus from '@ospin/nexus'
import App from '~/components/App'
import { detectEnv } from '~/config/detectEnv'
import './styles/index.css'
import './styles/semanticoverrides.css'

nexus.configure({ ENV: detectEnv() })

ReactDOM.render(
  <App />,
  document.getElementById('app'),
)
