import React from 'react'
import { Segment, Header, Icon, Modal } from 'semantic-ui-react'

import ButtonWithConfirm from '~/components/shared/ButtonWithConfirm'
import User from '~/models/User'
import { useUpdateUser } from '~/contexts/users/users-context'
import TriggerableFullScreenModal from '~/components/shared/hocs/modals/TriggerableFullScreenModal'
import UserDetails from './UserDetails'

const ROLE_NAME_COLOR = {
  admin: 'violet',
  developer: 'blue',
  user: 'green',
}

const UserRoleControlModal = ({
  user,
  open,
  setOpen,
  updateUserRole,
}) => {

  const updateUser = useUpdateUser()

  const callUpdateUserRole = async role => {
    updateUser(user.id, { role })
    updateUserRole(user.id, role)
  }

  const renderChangeRoleButton = roleName => (
    <ButtonWithConfirm
      key={roleName}
      tooltipDisabled
      catchAndDisplayConfirmErrors
      color={ROLE_NAME_COLOR[roleName]}
      buttonText={roleName}
      confirmHeader={`Change to ${roleName}`}
      confirmContent='Are you sure?'
      confirmText='I am the senate'
      cancelText='Oh hell no. Thank god for this modal.'
      onConfirm={() => callUpdateUserRole(roleName)}
      confirmErrorMsgHeader='There was a problem changing the user role...'
    />
  )

  const renderContent = () => (
    <Modal.Content>
      <Modal.Description>
        <UserDetails user={user} />
        <Segment placeholder data-testid='role-control'>
          <Header icon>
            <Icon name='key' />
          </Header>
          <Segment.Inline>
            { User.ROLE_NAMES.reverse().map(roleName => (
              renderChangeRoleButton(roleName)
            ))}
          </Segment.Inline>
        </Segment>
      </Modal.Description>
    </Modal.Content>
  )

  return (
    <TriggerableFullScreenModal
      open={open}
      setOpen={setOpen}
      titleText='Role Control'
      closeButtonText='close'
      closeButtonDisabled={false}
      renderContent={renderContent}
    />
  )
}

export default UserRoleControlModal
