import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useActiveUserState } from '~/contexts/active-user/active-user-context'
import ScreenWrapper from '~/components/shared/hocs/ScreenWrapper'
import LicencesTable from '~/components/licences/LicencesTable'
import CreateLicence from '~/components/licences/CreateLicence'
import { calcTotalPages, calculateSkippedItems } from '~/utils/pagination'
import SearchByProperty from '~/components/shared/SearchByProperty'
import { getQueryParams } from '~/utils/query'
import LicencesControlPagination from './LicencesControlPagination'

const textQueryFieldOptions = [
  { text: 'username', value: 'claimerUserName' },
  { text: 'device name', value: 'deviceName' },
  { text: 'device ID', value: 'deviceId' },
]

const licencesPerPage = 20
const DEFAULT_QUERY_PARAMS = {
  limit: licencesPerPage,
  skip: 0,
  sortBy: 'createdAt',
  sortOrder: 'DESC',
  textQuery: '',
  textQueryField: 'claimerUserName',
}

const sortableKeys = ['createdAt', 'claimedAt', 'deviceId', 'purchaseOrderId']

const LicenceScreen = props => {
  const { location, history } = props

  const [licences, setLicences] = useState([])
  const [licenceTypes, setLicenceTypes] = useState([])
  const [totalLicences, setTotalLicences] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useActiveUserState()

  const addNewLicence = licence => {
    /* the newlyCreated key is used just to highlight the row in the table */
    setLicences([ { ...licence, newlyCreated: true, creator: user }, ...licences ])
  }

  const updateActivePage = (_, data) => {
    const query = new URLSearchParams(location.search)
    query.set('skip', calculateSkippedItems(data, licencesPerPage))

    history.replace(`?${query.toString()}`)
  }

  const toggleSortOrder = querySortOrder => (
    querySortOrder === 'ASC' ? 'DESC' : 'ASC'
  )

  const setSortByQueryParamsOnClick = header => {
    const query = new URLSearchParams(location.search)
    const querySortBy = query.get('sortBy')
    const querySortOrder = query.get('sortOrder')

    if (querySortBy === header) {
      query.set('sortOrder', toggleSortOrder(querySortOrder))
    }

    if (sortableKeys.includes(header)) {
      query.set('sortBy', header)
    }

    /* go back to page 1 when changing filters */
    query.set('skip', 0)

    history.replace(`?${query.toString()}`)
  }

  const updateLicenceRow = updatedLic => {
    const updatedLicencesList = licences.map(lic => {
      if (lic.id === updatedLic.id) {
        return {
          ...lic,
          ...updatedLic,
        }
      }
      return lic
    })
    setLicences(updatedLicencesList)
  }

  const {
    limit,
    skip,
    sortBy,
    sortOrder,
    textQuery,
    textQueryField,
  } = getQueryParams(location, DEFAULT_QUERY_PARAMS)

  return (
    <ScreenWrapper screenTitle='Licences'>
      <CreateLicence
        addNewLicence={addNewLicence}
        isLoading={isLoading}
        licenceTypes={licenceTypes}
      />
      <SearchByProperty
        isLoading={isLoading}
        textQueryFieldOptions={textQueryFieldOptions}
        textQuery={textQuery}
        textQueryField={textQueryField}
      />
      <LicencesControlPagination
        activePage={Math.floor(skip / limit) + 1}
        totalLicences={totalLicences}
        totalPages={calcTotalPages(totalLicences, licencesPerPage)}
        licencesPerPage={licencesPerPage}
        updateActivePage={updateActivePage}
        isLoading={isLoading}
      />
      <LicencesTable
        licenceTypes={licenceTypes}
        licences={licences}
        skip={skip}
        limit={limit}
        sortBy={sortBy}
        sortOrder={sortOrder}
        sortableKeys={sortableKeys}
        textQuery={textQuery}
        textQueryField={textQueryField}
        totalLicences={totalLicences}
        setTotalLicences={setTotalLicences}
        setLicenceTypes={setLicenceTypes}
        setLicences={setLicences}
        defaultQueryParams={DEFAULT_QUERY_PARAMS}
        setSortByQueryParamsOnClick={setSortByQueryParamsOnClick}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        updateLicenceRow={updateLicenceRow}
      />
    </ScreenWrapper>
  )
}

export default withRouter(LicenceScreen)
