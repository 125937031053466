import React, { useEffect } from 'react'

import OspinLogoLoader from '~/components/loaders/OspinLogoLoader'
import { useAutoAuthenticate, useActiveUserState } from '~/contexts/active-user/active-user-context'

const AutoLogin = () => {
  const { autoAuthComplete } = useActiveUserState()
  const autoAuthenticate = useAutoAuthenticate()

  useEffect(() => {
    if (autoAuthComplete) return
    autoAuthenticate()
  }, [ autoAuthenticate, autoAuthComplete ])

  return <OspinLogoLoader />
}

export default AutoLogin
