import nexus from '@ospin/nexus'
import ZoomableUserProperty from '~/components/shared/ZoomableUserProperty'
import ZoomableDeviceProperty from '~/components/shared/ZoomableDeviceProperty'
import React, { useState, createRef, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Button, Input, Dropdown, Icon, Message } from 'semantic-ui-react'
import SectionLoader from '~/components/loaders/SectionLoader'
import { msToShortDate } from '~/utils/timeDisplay'
import { setQueryParametersOnPageLoad } from '~/utils/query'
import LinkLicenceModal from './LinkLicenceModal'
import './LicencesTable.css'

const copyTextToClipboard = async text => {
  await navigator.clipboard.writeText(text)
}

const TableHeaderItems = [
  'created at',
  'type',
  'claimed by',
  'claimed at',
  'expire date',
  'invoice ID',
  'Purchase order ID',
  'key',
  'device',
  'deviceId',
  'created by',
  'state',
]

const mapItemToSortBy = item => {
  const mapping = {
    'created at': 'createdAt',
    'claimed at': 'claimedAt',
    'Purchase order ID': 'purchaseOrderId',
    deviceId: 'deviceId',
  }
  return mapping[item]
}

const StateDropdown = ({ lic, updateLicenceRow }) => {

  const options = [
    { text: 'waiting for delivery', value: 'waiting for delivery', key: 'waiting' },
    { text: 'delivered to Heidolph', value: 'delivered to Heidolph', key: 'heidolph' },
    { text: 'delivered to customer', value: 'delivered to customer', key: 'customer' },
  ]

  const updateState = async newState => {
    try {
      const { data: updatedLicence } = await nexus.licence
        .update({ licenceId: lic.id, params: { state: newState } })
      updateLicenceRow(updatedLicence)
    } catch (_) {}

  }

  return (
    <Dropdown
      data-testid={`licence-table-state-cell-${lic.id}`}
      selection
      options={options}
      value={lic.state}
      onChange={(_, { value }) => {
        updateState(value)
      }}
    />
  )
}

const UpdateButton = props => {

  const {
    lic,
    updateLicenceRow,
    fieldName,
  } = props

  const [editing, setEditing] = useState(false)
  const [localFieldValue, setLocalFieldValue] = useState({
    invoiceId: lic.invoiceId,
    purchaseOrderId: lic.purchaseOrderId,
  })

  const updateFieldValue = async (newValue, field) => {
    if (newValue === lic[field]) return

    try {
      const { data: updatedLicence } = await nexus.licence
        .update({ licenceId: lic.id, params: { [field]: newValue } })

      updateLicenceRow(updatedLicence)
      setLocalFieldValue({ [field]: newValue })
    } catch (_) {
    } finally {
      setEditing(false)
    }
  }

  const ref = createRef()
  const shownValue = editing ? localFieldValue[fieldName] : lic[fieldName]

  return (
    <div className='licence-table-centered-cell'>
      <Input
        data-testid={`licence-table-${fieldName.toLowerCase()}-cell-${lic.id}`}
        ref={ref}
        style={{ opacity: 1 }}
        value={shownValue || ''}
        onChange={({ target }) => {
          if (!editing) setEditing(true)
          setLocalFieldValue({ [fieldName]: target.value })
        }}
        onBlur={({ target }) => {
          updateFieldValue(target.value, fieldName)
        }}
      />
    </div>
  )
}

const LicenceKey = ({ licenceKey, id }) => {

  const [shownKey, setShowKey] = useState(false)

  return (
    <div className='licence-table-centered-cell'>
      {shownKey ? licenceKey : 'XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX'}
      &nbsp;
      <Button.Group>
        <Button
          primary
          compact
          icon={shownKey ? 'eye slash' : 'eye'}
          onClick={() => setShowKey(!shownKey)}
          data-testid={`show-key-btn-${id}`}
        />
        <Button
          primary
          compact
          icon='copy'
          onClick={() => copyTextToClipboard(licenceKey)}
          data-testid={`copy-key-btn-${id}`}
        />
      </Button.Group>
    </div>
  )
}

const LinkLicencesButton = props => {
  const {
    licence,
    updateLicenceRow,
  } = props

  const [openLinkLicenceModal, setOpenLinkLicenceModal] = useState(false)

  if (licence.deviceId) {
    return <i>The associated device was not found</i>
  }

  return (
    <div>
      <Button
        onClick={() => setOpenLinkLicenceModal(!openLinkLicenceModal)}
        compact
        primary
      >
        Link
      </Button>
      {openLinkLicenceModal
        && (
          <LinkLicenceModal
            licence={licence}
            setOpenLinkLicenceModal={setOpenLinkLicenceModal}
            open={openLinkLicenceModal}
            updateLicenceRow={updateLicenceRow}
          />
        )}
    </div>
  )
}

const LicencesTable = ({
  location,
  history,
  licences,
  licenceTypes,
  totalLicences,
  setTotalLicences,
  setLicenceTypes,
  setLicences,
  skip,
  limit,
  sortBy,
  sortOrder,
  textQuery,
  textQueryField,
  setSortByQueryParamsOnClick,
  sortableKeys,
  isLoading,
  setIsLoading,
  updateLicenceRow,
  defaultQueryParams,
}) => {

  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const getClassNameForHeader = item => {
    const pointerCursorClass = 'licences-table-clickable-header'
    const highlightedHeaderClass = 'licences-table-active-sortable-header'
    const query = new URLSearchParams(location.search)
    const querySortBy = query.get('sortBy')

    if (sortableKeys.includes(item)) {
      if (item === querySortBy) {
        return `${pointerCursorClass} ${highlightedHeaderClass}`
      }

      return pointerCursorClass
    }
  }

  const getSortIcon = header => {
    const query = new URLSearchParams(location.search)
    const querySortBy = query.get('sortBy')
    const querySortOrder = query.get('sortOrder')

    if (header === querySortBy) {
      if (querySortOrder === 'ASC') {
        return 'sort ascending'
      }

      return 'sort descending'
    }
  }

  useEffect(() => {
    const fetchLicences = async () => {
      const query = {
        embedDevices: true,
        embedUsers: true,
        limit,
        skip,
        sortBy,
        sortOrder,
      }

      if (textQuery && textQueryField) {
        query.textQuery = textQuery
        query.textQueryField = textQueryField
      }

      setIsLoading(true)

      try {
        const [{ data }, { data: types }] = await Promise.all([
          nexus.licence.list(query),
          nexus.licence.type.list(),
        ])

        const { licences: fetchedLicences, count } = data

        setTotalLicences(count)
        setLicenceTypes(types)
        setLicences(fetchedLicences)
        setIsError(false)
        setErrorMsg('')
      } catch ({ message }) {
        setIsError(true)
        setErrorMsg(message)
      } finally {
        setIsLoading(false)
      }
    }
    fetchLicences()
    setQueryParametersOnPageLoad(location, history, defaultQueryParams)
  }, [skip, sortBy, sortOrder, textQuery, textQueryField])

  if (isLoading) return <SectionLoader />
  if (totalLicences === 0) return <Message content='No licences found' />
  if (isError) return <Message error content={errorMsg} />

  const renderLincence = lic => (
    <Table.Row key={lic.id} positive={lic.newlyCreated}>
      <Table.Cell content={msToShortDate(lic.createdAt)} />
      <Table.Cell content={licenceTypes.find(type => type.id === lic.typeId)?.name} />
      <Table.Cell content={lic.claimer ? <ZoomableUserProperty property={lic.claimer.userName} userId={lic.claimer.id} /> : '-'} />
      <Table.Cell content={lic.claimedAt ? msToShortDate(lic.claimedAt) : '-'} />
      <Table.Cell content={lic.expiresAt ? msToShortDate(lic.expiresAt) : '-'} />
      <Table.Cell content={<UpdateButton lic={lic} updateLicenceRow={updateLicenceRow} fieldName='invoiceId' />} />
      <Table.Cell content={<UpdateButton lic={lic} updateLicenceRow={updateLicenceRow} fieldName='purchaseOrderId' />} />
      <Table.Cell content={<LicenceKey licenceKey={lic.key} id={lic.id} />} />
      <Table.Cell content={lic.device
        ? <ZoomableDeviceProperty deviceId={lic.device.id} property={lic.device.name} />
        : <LinkLicencesButton licence={lic} updateLicenceRow={updateLicenceRow} />}
      />
      <Table.Cell content={lic.deviceId} />
      <Table.Cell content={lic.creator ? <ZoomableUserProperty property={lic.creator.userName} userId={lic.creator.id} /> : '-'} />
      <Table.Cell content={<StateDropdown lic={lic} updateLicenceRow={updateLicenceRow} />} />
    </Table.Row>
  )

  return (
    <Table celled structured padded className='top-border-ospin-red font-size-10'>
      <Table.Header>
        <Table.Row>
          {TableHeaderItems.map(item => (
            <Table.HeaderCell
              key={item}
              onClick={() => setSortByQueryParamsOnClick(mapItemToSortBy(item))}
              className={getClassNameForHeader(mapItemToSortBy(item))}
              data-testid={`licences-table-${mapItemToSortBy(item)}-header`}
            >
              {item}
              <Icon
                name={getSortIcon(mapItemToSortBy(item))}
                className='licences-table-sort-icon'
              />
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {licences
          .map(renderLincence)}
      </Table.Body>
    </Table>
  )

}

export default withRouter(LicencesTable)
