import React from 'react'
import { Button, Modal, Input } from 'semantic-ui-react'
import TriggerableFullScreenModal from '~/components/shared/hocs/modals/TriggerableFullScreenModal'
import nexus from '@ospin/nexus'

const DeleteDeviceModal = ({
  open,
  setOpen,
  setErrorModalOpen,
  setErrorModalText,
  deviceName,
  deviceId,
  removeDeviceById,
}) => {

  const [ loading, setLoading ] = React.useState(false)
  const [ userHasConfirmedDeviceName, setUserHasConfirmedDeviceName ] = React.useState(false)

  async function handleDeleteDevice() {
    setLoading(true)

    try {
      await nexus.device.remove({ deviceId })
      removeDeviceById(deviceId)
    } catch (e) {
      setErrorModalOpen(true)
      setErrorModalText(`Delete device name: ${deviceName} and device id: ${deviceId} - ${e.message}`)
      setLoading(false)
    }
  }

  function handleInputChange({ target: { value } }) {
    setUserHasConfirmedDeviceName((value.trim() === deviceName.trim()))
  }

  const renderContent = () => (
    <Modal.Content>
      <Modal.Description>
        <div>
          Device ID:
          <b>
            {deviceId}
          </b>
        </div>

        <h3>This action requires the following:</h3>
        <ul>
          <li>The device must be offline and on dev</li>
          <li>The user must have admin access to the device</li>
          <li>The device is not running any processes</li>
          <li>The device must not have any associated processes</li>
        </ul>

        <h3>This will delete the following:</h3>
        <ul>
          <li>The device</li>
          <li>Any association to this device</li>
        </ul>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        >
          <Input
            style={{ width: '100%' }}
            error={!userHasConfirmedDeviceName}
            placeholder='Enter device name to confirm'
            onChange={handleInputChange}
          />
          <br />
          <Button
            loading={loading}
            color='red'
            disabled={!userHasConfirmedDeviceName || loading}
            onClick={() => handleDeleteDevice()}
          >
            DELETE DEVICE FOREVER
          </Button>
        </div>
      </Modal.Description>
    </Modal.Content>
  )

  return (
    <TriggerableFullScreenModal
      open={open}
      setOpen={setOpen}
      titleText={`Delete ${deviceName}`}
      closeButtonText='close'
      closeButtonDisabled={false}
      renderContent={renderContent}
    />
  )
}

export default DeleteDeviceModal
