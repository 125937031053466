import React from 'react'
import { List, Table, Label } from 'semantic-ui-react'
import ResourceAccess from '~/utils/access/ResourceAccess'
import './UsersTable.css'
import ActiveUserAuthGate from '~/components/shared/hocs/ActiveUserAuthGate'
import ZoomableUserProperty from '~/components/shared/ZoomableUserProperty'
import ZoomableDeviceProperty from '~/components/shared/ZoomableDeviceProperty'
import User from '~/models/User'
import UserActionButtons from './UserActionButtons'

const renderUserAccessGroupName = (device, userId) => {
  const userAccessGroup = ResourceAccess.getAccessGroup(device, userId)

  return (
    <Label size='small'>
      {userAccessGroup.name}
    </Label>
  )
}

const renderDeviceOwnerLabel = (device, userId) => {
  if (ResourceAccess.isOwnerOf(device, userId)) {
    return (
      <Label size='small' color='blue'>
        owner
      </Label>
    )
  }
}

const renderUserDeviceListContent = (userDevices, userId) => (
  <List>
    {userDevices.map(device => (
      <List.Item key={device.id}>
        <ZoomableDeviceProperty property={device.name} deviceId={device.id} />
        <div className='user-table-device-list-label-container'>
          {renderUserAccessGroupName(device, userId)}
          {renderDeviceOwnerLabel(device, userId)}
        </div>
      </List.Item>
    ))}
  </List>
)

const UserTableRow = ({ user, updateUserRole }) => {
  const {
    id: userId,
    role,
    email,
    fullName,
    userName,
    devices: userDevices,
  } = user

  return (
    <Table.Row>
      <Table.Cell content={<ZoomableUserProperty userId={userId} property={userName} />} />
      <Table.Cell content={fullName} />
      <Table.Cell content={email} />
      <Table.Cell content={role} />
      <Table.Cell content={userId} />
      <Table.Cell content={renderUserDeviceListContent(userDevices, userId)} />
      <ActiveUserAuthGate minRoleReq={User.ROLES.admin}>
        <Table.Cell content={<UserActionButtons user={user} updateUserRole={updateUserRole} />} />
      </ActiveUserAuthGate>
    </Table.Row>
  )
}

export default UserTableRow
