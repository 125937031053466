import React from 'react'
import { Segment, Header, Icon } from 'semantic-ui-react'

import '~/styles/components/shared/hocs/SectionWrapper.css'
import useToggle from '~/utils/customHooks/useToggle'

const getFoldedStyles = isFolded => ({
  iconName: isFolded ? 'triangle right' : 'triangle down',
  headerClasses: isFolded ? 'section-header collapsed' : 'section-header',
})

const SectionWrapper = ({ title, children, startFolded = true }) => {
  const [ isFolded, toggleFolded ] = useToggle(startFolded)
  const { iconName, headerClasses } = getFoldedStyles(isFolded)

  return (
    <>
      <Segment className={headerClasses} attached='top' onClick={toggleFolded}>
        <Icon name={iconName} />
        <Header textAlign='left' className='section-header-text'>
          {title}
        </Header>
      </Segment>
      {!isFolded && (
        <Segment attached className='section-body'>
          {children}
        </Segment>
      )}
    </>
  )
}

export default SectionWrapper
