import React, { useState, createRef } from 'react'
import { Input } from 'semantic-ui-react'
import nexus from '@ospin/nexus'

const SerialNumberInput = ({ device, updateDevice }) => {
  const { id, serialNumber, owner } = device

  const [editing, setEditing] = useState(false)
  const [localSerialNumber, setLocalSerialNumber] = useState(serialNumber)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const updateSerialNumber = async newSerialNumber => {
    if (newSerialNumber === serialNumber) {
      setIsLoading(false)
      return
    }

    try {
      const { data: updatedDevice } = await nexus.device.update(
        { deviceId: id, params: { serialNumber: newSerialNumber } },
      )
      updateDevice(updatedDevice, owner)
      setLocalSerialNumber(newSerialNumber)
      setIsError(false)
    } catch (e) {
      setIsError(true)
    } finally {
      setEditing(false)
      setIsLoading(false)
    }
  }

  const ref = createRef()
  const showSerialNumber = editing ? localSerialNumber : serialNumber

  return (
    <div>
      <Input
        ref={ref}
        data-testid={`device-table-serial-number-input-cell-${id}`}
        value={showSerialNumber}
        loading={isLoading}
        error={isError}
        onChange={({ target }) => {
          if (!editing) setEditing(true)
          setLocalSerialNumber(target.value)
        }}
        onBlur={({ target }) => {
          setIsLoading(true)
          updateSerialNumber(target.value)
        }}
      />
    </div>
  )
}

export default SerialNumberInput
