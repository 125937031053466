import React from 'react'
import GetDeviceCertificateModal from '~/components/devices/deviceCertificates/GetDeviceCertificateModal'
import ButtonWithConfirm from '~/components/shared/ButtonWithConfirm'
import ButtonOpenModal from '~/components/shared/hocs/buttons/ButtonOpenModal'
import ActiveUserAuthGate from '~/components/shared/hocs/ActiveUserAuthGate'
import nexus from '@ospin/nexus'
import User from '~/models/User'
import { detectEnv } from '~/config/detectEnv'
import DeleteDeviceModal from '../../DeleteDevice/DeleteDeviceModal'
import GetSSHAccess from '../../../manageacces/GetSSHAccess'

const STAGES_THAT_ALLOW_DEVICE_DELETION = ['dev', 'staging']

const DeviceActionButtons = ({ device, removeDeviceById }) => {
  const { id: deviceId, name: deviceName, lastActiveStage } = device
  const env = detectEnv()
  const allowDeleteDevices = STAGES_THAT_ALLOW_DEVICE_DELETION
    .some(stage => env === stage && lastActiveStage === stage)
  return (
    <>
      {
        allowDeleteDevices && (
          <ActiveUserAuthGate minRoleReq={User.ROLES.admin}>
            <ButtonOpenModal
              inverted
              circular
              size='mini'
              tooltip='delete device'
              tooltipPosition='left center'
              icon='delete'
              color='red'
              errorHeaderText='There was a problem while deleting a device'
            >
              <DeleteDeviceModal
                deviceId={deviceId}
                deviceName={deviceName}
                removeDeviceById={removeDeviceById}
              />
            </ButtonOpenModal>
          </ActiveUserAuthGate>
        )
      }

      <ButtonOpenModal
        inverted
        circular
        size='mini'
        icon='file alternate'
        buttonClassName='device-list-item-button'
        color='blue'
        tooltip='see device certificates'
        tooltipPosition='left center'
        errorHeaderText='There was a problem fetching the certificate!'
      >
        <GetDeviceCertificateModal
          deviceId={deviceId}
          deviceName={deviceName}
        />
      </ButtonOpenModal>

      <ActiveUserAuthGate minRoleReq={User.ROLES.admin}>
        <ButtonWithConfirm
          inverted
          circular
          catchAndDisplayConfirmErrors
          size='mini'
          icon='delete calendar'
          buttonClassName='device-list-item-button'
          color='orange'
          tooltip='delete all logs'
          tooltipPosition='left center'
          confirmHeader={`Delete Device Logs for ${deviceName}?!`}
          confirmContent='This is very destructive. Are you sure?'
          confirmText='Yes, I am sure.'
          onConfirm={async () => {
            await nexus.log.device.deleteMany(deviceId)
            /* istanbul ignore next -- even with tests this was not being covered? */
          }}
          cancelText='Oh hell no.'
        />
      </ActiveUserAuthGate>

      <ButtonOpenModal
        inverted
        circular
        size='mini'
        icon='window maximize outline'
        buttonClassName='device-list-item-button'
        color='green'
        tooltip='Get SSH Access'
        tooltipPosition='left center'
        errorHeaderText='Failed to get SSH connection'
      >
        <GetSSHAccess
          deviceId={deviceId}
          deviceName={deviceName}
        />
      </ButtonOpenModal>
    </>
  )
}

export default DeviceActionButtons
