import React from 'react'
import { Pagination } from 'semantic-ui-react'
import { generatePaginationSentence } from '~/utils/pagination'

const LicencesControlPagination = ({
  activePage,
  totalLicences,
  totalPages,
  licencesPerPage,
  updateActivePage,
  isLoading,
}) => (
  <>
    {totalLicences > 0 ? (
      <div>
        <Pagination
          disabled={isLoading}
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={updateActivePage}
        />
        <span className='licences-table-pagination-sentence'>
          {generatePaginationSentence(activePage, licencesPerPage, totalLicences)}
        </span>
      </div>
    ) : null}
  </>
)

export default LicencesControlPagination
