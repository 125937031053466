import React, { useEffect, useState } from 'react'
import nexus from '@ospin/nexus'
import { downloadFile } from '~/utils/download'
import { Button, Grid, Message, Segment } from 'semantic-ui-react'
import ScreenWrapper from '~/components/shared/hocs/ScreenWrapper'

export default function Metrics() {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ error, setError ] = useState(null)

  async function downloadMetric() {
    try {
      setError(null)
      setIsLoading(true)
      const { data: { file } } = await nexus.utils.getMetrics()
      downloadFile('metrics.xlsx', Buffer.from(file), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    } catch ({ message }) {
      setError(message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(async () => {

  }, [])

  return (
    <ScreenWrapper screenTitle='Metrics'>
      <Segment>
        <Grid columns='equal' style={{ width: '100%' }}>
          <Grid.Column width={8}>
            Click the button to download the application metrics
          </Grid.Column>
          <Grid.Column width={8}>
            <Button primary onClick={() => downloadMetric()} floated='right' content='Download' loading={isLoading}/>

          </Grid.Column>

        </Grid>
        {error ? <Message error content={error} /> : null}

      </Segment>
    </ScreenWrapper>
  )
}
