import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import Login from '~/components/authentication/Login'

// this helps routes persist in the url on hard refresh
const UnAuthenticatedRoutes = () => (
  <div data-testid='unauthed-routes'>
    <Switch>
      <Redirect exact from='/logout' to='/login' />
      <Redirect exact from='/' to='/login' />
      <Route path='/'>
        <Login />
      </Route>
    </Switch>
  </div>
)

export default UnAuthenticatedRoutes
