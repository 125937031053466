import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import nexus from '@ospin/nexus'
import { detectEnv } from '~/config/detectEnv'
import { OspinPusherClient } from '@ospin/pusher'

import PrivateRoute from '~/components/routing/PrivateRoute'
import DevicesScreen from '~/components/devices/DevicesScreen'
import LicenceScreen from '~/components/licences/LicenceScreen'
import UsersScreen from '~/components/users/UsersScreen'
import AdminScreen from '~/components/admin/AdminScreen'
import AutoLogout from '~/components/authentication/AutoLogout'
import ActiveUserAuthGate from '~/components/shared/hocs/ActiveUserAuthGate'
import User from '~/models/User'
import Documentation from '~/components/documentation/Documentation'
import FunctionalityDescriptionEditor from '~/components/functionalityDescriptionEditor/FunctionalityDescriptionEditor'
import FunctionalityGraphTemplates from '~/components/functionalityGraphTemplates/FunctionalityGraphTemplates'
import FunctionalityDefinitions from '~/components/functionalityDefinitions/FunctionalityDefinitions'
import DownloadMetrics from '~/components/metrics/Metrics'
import { useActiveUserState } from '~/contexts/active-user/active-user-context'
import LicenceTypeScreen from '~/components/licenceTypes/LicenceTypesScreen'

const AuthenticatedRoutes = () => {

  const { user } = useActiveUserState()

  useEffect(() => {
    OspinPusherClient
      .connect({
        env: detectEnv(),
        userId: user.id,
        authDeviceSubscriptions: nexus.user.pusher.subscriptions.device.authorizeMany,
        authDeviceProcessSubscriptions: nexus.user.pusher.subscriptions.device.process
          .authorizeMany,
      })
  }, [])

  return (
    <Switch>
      <PrivateRoute exact path='/admin'>
        <ActiveUserAuthGate minRoleReq={User.ROLES.admin}>
          <AdminScreen />
        </ActiveUserAuthGate>
      </PrivateRoute>
      <PrivateRoute exact path='/users'>
        <ActiveUserAuthGate minRoleReq={User.ROLES.admin}>
          <UsersScreen />
        </ActiveUserAuthGate>
      </PrivateRoute>
      <PrivateRoute exact path='/devices'>
        <DevicesScreen />
      </PrivateRoute>
      <PrivateRoute exact path='/licences'>
        <LicenceScreen />
      </PrivateRoute>
      <PrivateRoute exact path='/licence-types'>
        <LicenceTypeScreen />
      </PrivateRoute>
      <PrivateRoute exact path='/documentation'>
        <Documentation />
      </PrivateRoute>
      <PrivateRoute exact path='/functionality-description-editor'>
        <FunctionalityDescriptionEditor />
      </PrivateRoute>
      <PrivateRoute exact path='/functionality-graph-templates'>
        <FunctionalityGraphTemplates />
      </PrivateRoute>
      <PrivateRoute exact path='/functionality-definitions'>
        <FunctionalityDefinitions />
      </PrivateRoute>
      <PrivateRoute exact path='/metrics'>
        <DownloadMetrics />
      </PrivateRoute>
      <Route exact path='/logout'>
        <AutoLogout />
      </Route>
      <Redirect from='/login' to='/devices' />
      <Redirect from='/' to='/devices' />
    </Switch>
  )
}

export default AuthenticatedRoutes
