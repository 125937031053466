import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Icon } from 'semantic-ui-react'
import './SideBar.css'

const MenuItem = ({
  name,
  iconName,
  active,
  text,
}) => (
  <Menu.Item
    data-testid={`${name}-sidebar-icon`}
    as={Link}
    to={name}
    active={active}
    className='sidebar-item'
  >
    <span>{text}</span>
    <Icon size='large' name={iconName} className='sidebar-icon' data-testid={`${name}-icon-testid`} />
  </Menu.Item>

)

export default MenuItem
