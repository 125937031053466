import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import ScreenWrapper from '~/components/shared/hocs/ScreenWrapper'
import { calcTotalPages, calculateSkippedItems } from '~/utils/pagination'
import SearchByProperty from '~/components/shared/SearchByProperty'
import { getQueryParams } from '~/utils/query'
import UsersTable from './sections/UsersTable/UsersTable'
import UsersControlPagination from './sections/UsersTable/UsersControlPagination'

const usersPerPage = 20

const textQueryFieldOptions = [
  { text: 'username', value: 'userName' },
  { text: 'full name', value: 'fullName' },
  { text: 'email', value: 'email' },
]

const DEFAULT_QUERY_PARAMS = {
  limit: usersPerPage,
  skip: 0,
  sortBy: 'userName',
  sortOrder: 'ASC',
  textQuery: '',
  textQueryField: 'userName',
}

const UsersScreen = props => {
  const { location, history } = props

  const [usersWithEmbedDevices, setUsersWithEmbedDevices] = useState([])
  const [totalUsers, setTotalUsers] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const updateActivePage = (_, data) => {
    const query = new URLSearchParams(location.search)
    query.set('skip', calculateSkippedItems(data, usersPerPage))
    history.replace(`?${query.toString()}`)
  }

  const toggleSortOrder = querySortOrder => (
    querySortOrder === 'ASC' ? 'DESC' : 'ASC'
  )

  const setSortByQueryParamsOnClick = header => {
    const query = new URLSearchParams(location.search)
    const querySortBy = query.get('sortBy')
    const querySortOrder = query.get('sortOrder')

    if (querySortBy === header) {
      query.set('sortOrder', toggleSortOrder(querySortOrder))
    }

    if (header === 'userName') {
      query.set('sortBy', 'userName')
    }

    if (header === 'fullName') {
      query.set('sortBy', 'fullName')
    }

    /* go back to page 1 when changing filters */
    query.set('skip', 0)

    history.replace(`?${query.toString()}`)
  }

  const {
    limit,
    skip,
    sortBy,
    sortOrder,
    textQuery,
    textQueryField,
  } = getQueryParams(location, DEFAULT_QUERY_PARAMS)

  return (
    <ScreenWrapper screenTitle='Users'>
      <SearchByProperty
        isLoading={isLoading}
        textQuery={textQuery}
        textQueryField={textQueryField}
        textQueryFieldOptions={textQueryFieldOptions}
      />
      <UsersControlPagination
        activePage={Math.floor(skip / limit) + 1}
        usersPerPage={usersPerPage}
        totalUsers={totalUsers}
        totalPages={calcTotalPages(totalUsers, usersPerPage)}
        updateActivePage={updateActivePage}
        isLoading={isLoading}
      />
      <UsersTable
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        limit={limit}
        skip={skip}
        sortBy={sortBy}
        sortOrder={sortOrder}
        textQuery={textQuery}
        textQueryField={textQueryField}
        usersWithEmbedDevices={usersWithEmbedDevices}
        setUsersWithEmbedDevices={setUsersWithEmbedDevices}
        setTotalUsers={setTotalUsers}
        setSortByQueryParamsOnClick={setSortByQueryParamsOnClick}
        totalUsers={totalUsers}
        defaultQueryParams={DEFAULT_QUERY_PARAMS}
      />
    </ScreenWrapper>
  )
}

export default withRouter(UsersScreen)
