import React from 'react'

import { Table, Icon } from 'semantic-ui-react'
import { setSortByQueryParamsOnClick } from '~/utils/query'

const TableHeaderItems = [
  { header: 'name', sortable: true, sortKey: 'name' },
  { header: 'id', sortable: true, sortKey: 'id' },
  { header: 'feature version', sortable: true, sortKey: 'featureVersion' },
  { header: 'firmware version', sortable: true, sortKey: 'firmwareVersion' },
  { header: 'online', sortable: true, sortKey: 'online' },
  { header: 'last active state', sortable: false, sortKey: null },
  { header: 'owner', sortable: false, sortKey: null },
  { header: 'serial number', sortable: true, sortKey: 'serialNumber' },
  { header: 'MAC addresses', sortable: false, sortKey: null },
  { header: ' ', sortable: false, sortKey: null },
]

const DeviceTableHeader = ({ history, location }) => {

  const getClassNameForHeader = sortKey => {
    const pointerCursorClass = 'devices-table-clickable-header'
    const highlightedHeaderClass = 'devices-table-active-sortable-header'
    const query = new URLSearchParams(location.search)
    const querySortBy = query.get('sortBy')

    if (sortKey === querySortBy) {
      return `${pointerCursorClass} ${highlightedHeaderClass}`
    }

    return pointerCursorClass
  }

  const getSortIcon = sortKey => {
    const query = new URLSearchParams(location.search)
    const querySortBy = query.get('sortBy')
    const querySortOrder = query.get('sortOrder')

    if (sortKey === querySortBy) {
      if (querySortOrder === 'ASC') {
        return 'sort ascending'
      }
      return 'sort descending'
    }
  }

  return (
    <Table.Row>
      {TableHeaderItems.map(({ header, sortable, sortKey }) => (
        <Table.HeaderCell
          key={header}
          onClick={sortable ? () => setSortByQueryParamsOnClick(history, location, sortKey) : null}
          className={sortable ? getClassNameForHeader(sortKey) : ''}
          data-testid={sortable ? `devices-table-${sortKey}-header` : `devices-table-${header}-header`}
        >
          {header}
          <Icon
            name={getSortIcon(sortKey)}
            className='devices-table-sort-icon'
          />
        </Table.HeaderCell>
      ))}
    </Table.Row>

  )
}

export default DeviceTableHeader
