import React, { useRef, useEffect } from 'react'
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'

const EditorArea = ({
  description,
  tempDescription,
  updateDescription,
  onValidationError,
  mode,
}) => {

  /* the editor is an uncontrolled input component!
  /* so we need to manage the state changes via the
  /* ref and useEffect
  /* https://github.com/vankop/jsoneditor-react/issues/3#issuecomment-653067717
  */
  const jsonEditorRef = useRef(null)

  useEffect(() => {
    /* reset the editor when new description is chosen or the current one saved */
    if (jsonEditorRef.current !== null) {
      jsonEditorRef.current.set(description)
    }
  }, [description])

  useEffect(() => {
    if (jsonEditorRef.current !== null) {
      jsonEditorRef.current.setMode(mode)
    }
  }, [mode])

  if (description === undefined) return null

  const setRef = instance => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor
    } else {
      jsonEditorRef.current = null
    }
  }

  /* the "value" here is really just the initial value */
  return (
    <div>
      <Editor
        ref={setRef}
        value={tempDescription}
        mode={mode}
        htmlElementProps={{ style: { height: 800 } }}
        onChange={updateDescription}
        onValidationError={onValidationError}
      />
    </div>
  )
}

export default EditorArea
