import nexus from '@ospin/nexus'

import User from '~/models/User'

export default async function autoAuthenticate(dispatch) {

  try {
    const { data: session } = await nexus.auth.getCurrentSession()

    const sessionUserSub = session.accessToken?.payload?.sub
    if (!sessionUserSub) throw new Error('No signInSessionUserSub found!')

    try {
      const { data: user } = await nexus.user.get(sessionUserSub)
      const authorized = User.hasMacrophageAccess(user)
      const payload = authorized
        ? { type: 'autoAuthenticate', payload: { session, user } }
        : { type: 'authFailure', payload: { authFailure: `${user.userName} does not have the required access level. User role: ${user.role}` } }

      dispatch(payload)
      return { success: authorized }
    } catch (e) {
      dispatch({ type: 'authFailure', payload: { authFailure: e.message } })
      return { success: false }
    }
  } catch (_) {
    dispatch({ type: 'autoAuthFailure' })
    return { success: false }
  }
}
