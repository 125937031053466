import React from 'react'
import ButtonOpenModal from '~/components/shared/hocs/buttons/ButtonOpenModal'
import UserRoleControlModal from './UserRoleControlModal'

const UserActionButtons = ({ user, updateUserRole }) => (
  <div className='user-list-item-action-buttons'>

    <ButtonOpenModal
      inverted
      circular
      size='mini'
      icon='key'
      color='blue'
      tooltip='role control'
      tooltipPosition='right center'
    >
      <UserRoleControlModal
        user={user}
        updateUserRole={updateUserRole}
      />
    </ButtonOpenModal>
  </div>
)

export default UserActionButtons
