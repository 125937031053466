/* eslint-disable max-classes-per-file */
import React from 'react'

import { useActiveUserState } from '~/contexts/active-user/active-user-context'
import User from '~/models/User'

class NoActiveUserError extends Error {
  constructor() {
    super('No active user found! Do not use ActiveUserAuthGate outside of a context where an active user is accessible')
    this.name = 'NoActiveUserError'
  }
}

class RoleNotFound extends Error {
  constructor(user) {
    super(`Active user role not found! Active User: ${user}`)
    this.name = 'RoleNotFound'
  }
}

const ActiveUserAuthGate = ({ children, minRoleReq }) => {
  const { user } = useActiveUserState()
  if (!user) throw new NoActiveUserError()
  if (!user.role) throw new RoleNotFound(user)

  return (
    <>
      { User.isRoleOrGreater(user, minRoleReq) ? children : null }
    </>
  )
}

export default ActiveUserAuthGate
