import nexus from '@ospin/nexus'
import React, { useEffect, useState } from 'react'
import { Dropdown, Table } from 'semantic-ui-react'
import ScreenWrapper from '~/components/shared/hocs/ScreenWrapper'
import { withRouter } from 'react-router-dom'

const tableHeaderItems = [
  'name',
  'subType',
]

const renderTemplate = template => {
  if (!template) return null

  const { functionalities } = template

  return (
    <Table celled structured padded className='top-border-ospin-red font-size-10'>
      <Table.Header>
        <Table.Row>
          {tableHeaderItems.map(item => (
            <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
          ))}
        </Table.Row>
        {functionalities.map(fct => (
          <Table.Row key={fct.props.name}>
            <Table.Cell content={fct.props.name} />
            <Table.Cell content={fct.subType} />
          </Table.Row>
        ))}
      </Table.Header>
    </Table>
  )
}

const mapTemplatesToOptions = descriptions => descriptions
  .map(template => ({
    text: template.name,
    value: template.id,
    key: template.id,
  }))
  .sort((a, b) => a.text.localeCompare(b.text))

const getTemplateIdFromQuery = location => {
  const query = new URLSearchParams(location.search)
  return query.get('templateId') || null
}

const FunctionalityGraphTemplates = ({ history, location }) => {

  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    setLoading(true)
    const { data: { fctGraphTemplates } } = await nexus
      .deviceDescription.template.list({})
    setTemplates(fctGraphTemplates)
    setLoading(false)
  }, [])

  const template = templates.find(t => t.id === getTemplateIdFromQuery(location))

  function setSelectedTemplateIdToUrl(id) {
    const query = new URLSearchParams(location.search)
    query.set('templateId', id)
    history.replace(`?${query.toString()}`)
  }


  return (
    <ScreenWrapper screenTitle='Functionality Graph Templates'>
      <Dropdown
        selection
        search
        loading={loading}
        disabled={loading}
        value={getTemplateIdFromQuery(location)}
        options={mapTemplatesToOptions(templates)}
        placeholder='Select Template'
        onChange={(_, { value }) => setSelectedTemplateIdToUrl(value)}
      />
      {renderTemplate(template)}
    </ScreenWrapper>
  )
}

export default withRouter(FunctionalityGraphTemplates)
