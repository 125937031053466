class Dispatchifier {
  constructor(dispatchToUse) {
    this.dispatchToUse = dispatchToUse
  }

  dispatchify(action) {
    return () => action.bind(null, this.dispatchToUse())
  }
}

export default Dispatchifier
