import { removeById, replaceById } from '~/utils/array'
import contextFactory from '~/contexts/helpers/contextFactory'
import Dispatchifier from '~/contexts/helpers/Dispatchifier'
// ACTIONS
import updateUser from '~/contexts/users/actions/updateUser'

/*******************************************************************************
 * DEFAULT STATE
 ******************************************************************************/
const DEFAULT_USERS_STATE = {
  users: [],
  usersLoaded: false,
}

/*******************************************************************************
 * REDUCER
 ******************************************************************************/
function usersReducer(usersState, action) {
  const { type, payload } = action

  switch (type) {
    case 'setUser': {
      const { users } = usersState
      const { user } = payload

      return {
        users: replaceById(users, user),
        usersLoaded: true,
      }
    }
    case 'removeUser': {
      const { users } = usersState
      const { userId } = payload
      return {
        ...usersState,
        users: removeById(users, { id: userId }),
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

/*******************************************************************************
 * CONTEXT CREATION
 ******************************************************************************/
const {
  ContextProvider: UsersProvider,
  useState: useUsersState,
  useDispatch: useUsersDispatch,
} = contextFactory({
  defaultState: DEFAULT_USERS_STATE,
  reducer: usersReducer,
  contextName: 'Users',
})

/*******************************************************************************
 * DISPATCHIFY ACTIONS
 ******************************************************************************/
const dispatchifier = new Dispatchifier(useUsersDispatch)

const useUpdateUser = dispatchifier.dispatchify(updateUser)

export {
  UsersProvider,
  useUsersState,
  useUsersDispatch,
  usersReducer,
  DEFAULT_USERS_STATE,
  // DISPATCHIFIED ACTIONS
  useUpdateUser,
}
