import React, { useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'

import TriggerableFullScreenErrorModal from '~/components/shared/modals/TriggerableFullScreenErrorModal'

const ButtonOpenModal = ({
  size,
  tooltipDisabled,
  circular,
  icon,
  buttonClassName,
  color,
  inverted,
  tooltip,
  tooltipPosition,
  buttonText,
  errorHeaderText,
  children: modalChild, // pass a single modal child please
}) => {

  const [ modalOpen, setModalOpen ] = useState()
  const [ errorModalOpen, setErrorModalOpen ] = useState()
  const [ errorModalText, setErrorModalText ] = useState()

  return (
    <>
      <TriggerableFullScreenErrorModal
        open={errorModalOpen}
        setOpen={setErrorModalOpen}
        headerText={errorHeaderText}
        textBlock={errorModalText}
      />

      { modalOpen && (
        React.cloneElement(modalChild, {
          setOpen: setModalOpen,
          open: modalOpen,
          setErrorModalOpen,
          setErrorModalText,
        })
      ) }

      <Popup
        disabled={tooltipDisabled}
        content={tooltip}
        position={tooltipPosition}
        trigger={(
          <Button
            disabled={modalOpen}
            data-testid={tooltip}
            onClick={() => setModalOpen(true)}
            size={size}
            circular={circular}
            icon={icon}
            className={buttonClassName}
            color={color}
            inverted={inverted}
          >
            { buttonText }
          </Button>
        )}
      />
    </>
  )
}

export default ButtonOpenModal
