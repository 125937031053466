import React, { useState } from 'react'
import { Sidebar, Menu, Image, Divider, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import MenuItem from '~/components/sidebar/MenuItem'
import ActiveUserAuthGate from '~/components/shared/hocs/ActiveUserAuthGate'
import logoIcon2 from '~/images/logo_icon2.png'
import User from '~/models/User'
import '~/components/sidebar/SideBar.css'

const ADMIN_SIDEBAR_ITEMS = [
  { name: 'admin', iconName: 'user secret', text: 'Admin' },
  { name: 'users', iconName: 'users', text: 'Users'},
]

const NON_ADMIN_SIDEBAR_ITEMS = [
  { name: 'devices', iconName: 'hdd', text: 'Devices' },
  { name: 'licences', iconName: 'key', text: 'Licences' },
  { name: 'licence-types', iconName: 'file', text: 'Licence Types' },
  { name: 'documentation', iconName: 'terminal', text: 'Docs' },
  { name: 'functionality-description-editor', iconName: 'edit', text: 'Description' },
  { name: 'functionality-graph-templates', iconName: 'cubes', text: 'Templates' },
  { name: 'functionality-definitions', iconName: 'file code', text: 'Definitions' },
  { name: 'metrics', iconName: 'chart line', text: 'Metrics' },
  { name: 'logout', iconName: 'log out', text: 'Logout' },
]

const getCurrentTabFromURL = location => (
  location.pathname.substr(1) || ''
)

function renderSidebarItems(items, location, collapsed) {
  const activeTab = getCurrentTabFromURL(location)
  return items.map(({ name, iconName, text }) => (
    <MenuItem
      key={name}
      name={name}
      iconName={iconName}
      active={activeTab === name}
      text={text}
      isCollapsed={collapsed}
    />
  ))
}

const SideBar = ({ location }) => {

  const [collapsed, setCollapsed] = useState(true)

  const toggleSidebar = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Sidebar
      as={Menu}
      visible
      vertical
      className={collapsed ? 'collapsed' : ''}
      width='thin'
      data-testid='sidebar'
    >
      <Divider hidden style={{ marginBottom: '12' }} />
      <Image src={logoIcon2} centered className='sidebar-logo' />
      <Divider hidden />
      <ActiveUserAuthGate minRoleReq={User.ROLES.admin}>
        { renderSidebarItems(ADMIN_SIDEBAR_ITEMS, location, collapsed) }
      </ActiveUserAuthGate>
      { renderSidebarItems(NON_ADMIN_SIDEBAR_ITEMS, location, collapsed) }

      <div className='sidebar-item-bottom-icon'>
        <Menu.Item
          onClick={toggleSidebar}
          className='sidebar-item'
        >
          <span>{' '}</span>
          <Icon
            name={collapsed ? 'chevron circle right' : 'chevron circle left'}
            size='large'
            data-testid='chevron-sidebar-icon'
            className='collapse-expand-icon'
          />
        </Menu.Item>
      </div>
    </Sidebar>
  )
}

export default withRouter(SideBar)
