import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AuthenticatedApp from '~/components/AuthenticatedApp'
import UnAuthenticatedApp from '~/components/UnAuthenticatedApp'
import { useActiveUserState } from '~/contexts/active-user/active-user-context'

const Main = () => {
  const { authenticated } = useActiveUserState()

  return (
    <BrowserRouter forceRefresh={false}>
      { authenticated ? <AuthenticatedApp /> : <UnAuthenticatedApp /> }
    </BrowserRouter>
  )
}

export default Main
