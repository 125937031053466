import React, { useEffect, useState } from 'react'
import { Loader, Menu } from 'semantic-ui-react'
import nexus from '@ospin/nexus'
import MQTTSchema from './MQTTSchema'
import HTTPSchema from './HTTPSchema'

export default function Documentation() {
  const [isLoading, setisLoading] = useState(true)
  const [ mode, setMode ] = useState('mqtt')
  const [ mqttAPIVersion, setMqttAPIVersion ] = useState()
  const [schemas, setSchemas] = useState(null)

  useEffect(async () => {
    try {
      const { data } = await nexus.utils.getDocumentation()
      setSchemas(data)
      setisLoading(false)
      setMqttAPIVersion(data.mqtt.tags[data.mqtt.tags.length - 1].name)
    } catch (_) {}

  }, [])

  if (isLoading) { return <Loader active /> }

  function renderSchemas() {
    // eslint-disable-next-line default-case
    switch (mode) {
      case 'mqtt':
        return <MQTTSchema schema={schemas.mqtt} mqttAPIVersion={mqttAPIVersion}/>
      case 'http':
        return <HTTPSchema schema={schemas.http}/>
    }

  }

  const renderMQTTAPIVersionSelection = () => {
    if (mode !== 'mqtt') return null
    return (
      <Menu>
        {schemas.mqtt.tags.map(tag => (
          <Menu.Item
            key={tag.name}
            name={tag.name}
            active={mqttAPIVersion === tag.name}
            onClick={() => setMqttAPIVersion(tag.name)}
            content={tag.name}
          />
        ))}
      </Menu>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <Menu>
        <Menu.Item
          name='mqtt'
          active={mode === 'mqtt'}
          onClick={() => setMode('mqtt')}
          content='MQTT'
        />
        <Menu.Item
          name='http'
          active={mode === 'http'}
          onClick={() => setMode('http')}
          content='HTTP'
        />
      </Menu>
      {renderMQTTAPIVersionSelection()}
      {renderSchemas()}
    </div>
  )
}
