import React, { useState, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, Message } from 'semantic-ui-react'
import { command } from '@ospin/nexus'
import { DeviceMaintenancePusherChannel } from '@ospin/pusher'
import { useActiveUserState } from '~/contexts/active-user/active-user-context'
import './SSHAccess.css'

const { EVENTS } = DeviceMaintenancePusherChannel

const renderSshPayload = (payload, copied, setCopied, statusColor) => {
  if (payload.error) {
    return (
      <Message negative>
        <Message.Header>Something went wrong</Message.Header>
        <p>{payload.error}</p>
      </Message>
    )
  }
  return (
    <>
      <Button className='ssh-access-button' color={statusColor}>
        <CopyToClipboard text={payload.ssh_url} onCopy={() => setCopied(true)}>
          <div>
            { copied ? 'copied to clipboard!' : 'click to copy ssh url to clipboard' }
          </div>
        </CopyToClipboard>
      </Button>

      <Button color={statusColor}>
        <a href={payload.web_url} target='_blank' rel='noopener noreferrer' style={{ color: 'white' }}>
          Open terminal in new browser tab
        </a>
      </Button>
    </>
  )
}

const commandOpenSsh = async (
  deviceId,
  setAwaitingSshPayload,
  setCommandRequestError,
  setStatusColor,
) => {
  setAwaitingSshPayload(true)
  setCommandRequestError('')
  try {
    await command.device.openSsh(deviceId)
  } catch (e) {
    setStatusColor('red')
    setCommandRequestError(`the open ssh command failed: ${e.message}`)
    setAwaitingSshPayload(false)
  }
}

const renderCommandRequestError = commandRequestError => {
  if (commandRequestError === '') return null
  return (
    <Message negative>
      <Message.Header data-testid='error-message-ssh-request'>Something went wrong</Message.Header>
      <p>{commandRequestError}</p>
    </Message>
  )
}

const SSHAccess = ({ deviceId }) => {
  const { user: { id: userId } } = useActiveUserState()
  const [ copied, setCopied ] = useState(false)
  const [ sshPayload, setSshPayload ] = useState(null)
  const [ awaitingSshPayload, setAwaitingSshPayload ] = useState(false)
  const [ statusColor, setStatusColor ] = useState('blue')
  const [ commandRequestError, setCommandRequestError ] = useState('')

  function handleReceivedPayload(payload) {
    setSshPayload(payload)
    setAwaitingSshPayload(false)
  }

  useEffect(() => {
    DeviceMaintenancePusherChannel
      .subscribe({ deviceId }, {
        [EVENTS.DEVICE_SSH_CONNECTION_OPENED]: ({ webURL, sshURL, error }) => {
          if (error) {
            setStatusColor('red')
          } else {
            setStatusColor('green')
          }
          handleReceivedPayload({ web_url: webURL, ssh_url: sshURL, error })
        },
      })

    return () => {
      DeviceMaintenancePusherChannel.unsubscribe({ deviceId })
    }
  }, [ deviceId, userId ])

  return (
    <>
      <Button
        primary
        loading={awaitingSshPayload}
        onClick={() => commandOpenSsh(
          deviceId,
          setAwaitingSshPayload,
          setCommandRequestError,
          setStatusColor,
        )}
      >
        Request SSH Connection
      </Button>

      { sshPayload ? renderSshPayload(sshPayload, copied, setCopied, statusColor) : null }
      { renderCommandRequestError(commandRequestError) }
    </>
  )
}

export default SSHAccess
