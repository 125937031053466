import nexus from '@ospin/nexus'
import React, { useState } from 'react'
import { Button, Dropdown } from 'semantic-ui-react'
import './CreateLicence.css'

const mapLicenceTypesToOptions = types => types
  .map(type => ({
    text: type.name,
    value: type.id,
    key: type.id,
  }))

const CreateLicence = ({ licenceTypes, isLoading, addNewLicence }) => {

  const [selectedLicenceTypeId, setSelectedLicenceTypeId] = useState(null)
  const [creatingLicence, setCreatingLicence] = useState(false)

  const createLicence = async () => {
    setCreatingLicence(true)

    const { data: licence } = await nexus.licence
      .create({ params: { typeId: selectedLicenceTypeId } })

    addNewLicence(licence)
    setCreatingLicence(false)
  }

  return (
    <div>
      <Dropdown
        className='create-licence-dropdown'
        selection
        defaultValue={null}
        options={mapLicenceTypesToOptions(licenceTypes)}
        disabled={isLoading}
        placeholder='Select licence type'
        onChange={(_, { value }) => setSelectedLicenceTypeId(value)}
        data-testid='licence-type-selection-dropdown'
      />
      <Button
        primary
        loading={creatingLicence}
        disabled={selectedLicenceTypeId === null || creatingLicence}
        onClick={createLicence}
      >
        Create Licence
      </Button>
    </div>
  )
}

export default CreateLicence
